import { getAuth, signOut } from "firebase/auth";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TempProfilePic from "../../assets/profile-pic-placeholder.png";
import fetchUserDataById from "../../helpers/fetchUserDataById";

export default function ProfileFrame({ user, ...props }) {
  const handleSignOut = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        sessionStorage.clear();
        window.location.reload();
        // console.log("User logged out");
      })
      .catch((error) => alert("Error signing out: " + error));
    // sessionStorage.clear();
    // console.log("cleared");
  };

  const [isStaff, setIsStaff] = useState(false);

  useEffect(() => {
    const checkStaff = async () => {
      const staffData = await fetchUserDataById(user.uid);
      setIsStaff(staffData.approved);
    };
    checkStaff();
  }, []);

  return (
    <div
      {...props}
      className="flex flex-col absolute bg-white text-black py-2 px-2 space-y-2 top-14 right-0 rounded-lg text-sm text-left w-[240px] shadow-md"
    >
      <div className="flex flex-col items-center font-normal ">
        <div className="bg-gradient-to-b from-[#2c2828] from-50% to-50% to-white rounded-tl-lg rounded-tr-lg w-full py-3 px-4">
          <img
            src={TempProfilePic}
            alt="profile pic"
            className="w-[80px] border-4 bg-white border-white rounded-full mx-auto"
            style={{ filter: "drop-shadow(5px 5px 5px rgba(0,0,0,0.2))" }}
          />
        </div>

        <h2 className="font-semibold">{user.displayName}</h2>
        <p className="text-secondary">{user.email}</p>
      </div>
      <div className="p-4">
        <ul className="font-normal space-y-2">
          <li className="text-gray-600 hover:text-black">
            <Link to="profile/edit">Edit Profile</Link>
          </li>
          {isStaff && (
            <li className="text-gray-600 hover:text-black">
              <Link to="/dashboard/compensation">Dashboard</Link>
            </li>
          )}

          <li className="text-gray-600 hover:text-black">
            <button onClick={handleSignOut}>Sign Out</button>
          </li>
        </ul>
      </div>
    </div>
  );
}
