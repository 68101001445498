import { collection, doc, onSnapshot, writeBatch } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../../firebaseConfig";
import fetchUserDataById from "../../../helpers/fetchUserDataById";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Oval } from "react-loader-spinner";

export default function Settings() {
  const [staffData, setStaffData] = useState([]);
  const [loading, setloading] = useState(false);
  const [updatedStaffData, setUpdatedStaffData] = useState([]);
  const [reqPending, setReqPending] = useState(false);
  const navigate = useNavigate();

  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        navigate("/login");
      } else {
        const staffData = await fetchUserDataById(user.uid);
        if (!staffData.approved || staffData.role !== "admin") {
          navigate("/");
        }
      }
    });

    // Clean up the event listener when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = listenToStaffData();
    return () => {
      unsubscribe();
    };
  }, []);

  const listenToStaffData = () => {
    setloading(true);
    const collectionRef = collection(db, "staff");
    const unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => {
        const docData = doc.data();
        return {
          ...docData,
          id: doc.id,
        };
      });
      setStaffData(data);
    });
    setloading(false);
    return unsubscribe;
  };

  const handleRoleChange = (index, value) => {
    const updatedData = [...updatedStaffData];
    if (!updatedData[index]) {
      updatedData[index] = {};
    }
    updatedData[index].role = value;
    setUpdatedStaffData(updatedData);
  };

  const handleApprovedChange = (index, checked) => {
    console.log(checked);
    const updatedData = [...updatedStaffData];
    if (!updatedData[index]) {
      updatedData[index] = {};
    }
    updatedData[index].approved = checked;
    setUpdatedStaffData(updatedData);
    console.log(updatedStaffData);
  };

  const handleApplyChanges = async () => {
    setReqPending(true);
    try {
      // Update the Firestore documents with the updated data
      const batch = writeBatch(db);
      staffData.forEach((staffMember, index) => {
        const updatedMember = updatedStaffData[index];
        if (updatedMember) {
          const updatedProperties = {
            role: updatedMember.role || staffMember.role,
            approved:
              updatedMember.approved !== undefined
                ? updatedMember.approved
                : staffMember.approved,
          };
          const docRef = doc(db, "staff", staffMember.id);
          batch.update(docRef, updatedProperties);
        }
      });
      await batch.commit();

      // Clear the updatedStaffData state
      setUpdatedStaffData([]);
      setReqPending(false);
    } catch (error) {
      alert("Error: " + error.message);
      console.error("Error updating staff data:", error);
      setReqPending(false);
    }
  };

  return (
    <div>
      {loading ? (
        <div className="flex items-center justify-center">
          <Oval
            height={80}
            width={80}
            color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <table className="table-auto w-full text-left whitespace-no-wrap">
          <thead>
            <tr>
              <th className="px-4 py-5 title-font tracking-wider font-medium text-gray-900 text-sm bg-primary rounded-tl rounded-bl">
                User Name
              </th>
              <th className="px-4 py-5 title-font tracking-wider font-medium text-gray-900 text-sm bg-primary">
                E-mail
              </th>
              <th className="px-4 py-5 title-font tracking-wider font-medium text-gray-900 text-sm bg-primary">
                Role
              </th>
              <th className="px-4 py-5 title-font tracking-wider font-medium text-gray-900 text-sm bg-primary">
                Approved
              </th>
              <th className="px-4 py-5 title-font tracking-wider font-medium text-gray-900 text-sm bg-primary"></th>
            </tr>
          </thead>
          <tbody>
            {staffData.map((staffMember, index) => (
              <tr key={index}>
                <td className="px-4 py-6">
                  {staffMember.firstName + " " + staffMember.lastName}
                </td>
                <td className="px-4 py-6">{staffMember.email}</td>
                <td className="px-4 py-6">
                  <select
                    value={updatedStaffData[index]?.role || staffMember.role}
                    onChange={(e) => handleRoleChange(index, e.target.value)}
                  >
                    <option value="staff">Staff</option>
                    <option value="admin">Admin</option>
                  </select>
                </td>
                <td className="px-4 py-6">
                  <input
                    type="checkbox"
                    defaultChecked={
                      updatedStaffData[index]?.approved || staffMember.approved
                    }
                    onChange={(e) =>
                      handleApprovedChange(index, e.target.checked)
                    }
                  />
                </td>
                <td>
                  <button
                    className="rounded-lg text-primary border-2 border-primary p-2 w-full hover:text-white hover:bg-primary transition 300ms"
                    onClick={handleApplyChanges}
                  >
                    {reqPending ? <span className="loader"></span> : "Apply"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
