import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../firebaseConfig";

export default async function FetchAllUserData(forceRefresh) {
  const cachedData = sessionStorage.getItem("userData");
  if (cachedData && !forceRefresh) {
    const cachedUsers = JSON.parse(cachedData);
    const cacheTimestamp = new Date(cachedUsers.timestamp);
    const currentTimestamp = new Date();
    const timeDiffMinutes = Math.floor(
      (currentTimestamp - cacheTimestamp) / (1000 * 60)
    );

    if (timeDiffMinutes <= 10) {
      return cachedUsers.data;
    }
  }

  const usersCollectionRef = collection(db, "users");
  const usersQuerySnapshot = await getDocs(query(usersCollectionRef));

  const users = [];
  for (const userDoc of usersQuerySnapshot.docs) {
    const userData = { id: userDoc.id, ...userDoc.data() };

    // Fetch "compensationConsult" sub-collection
    const compensationConsultRef = collection(
      userDoc.ref,
      "compensationConsults"
    );
    const compensationConsultSnapshot = await getDocs(
      query(compensationConsultRef)
    );
    const compensationConsultData = [];
    compensationConsultSnapshot.forEach((subDoc) => {
      compensationConsultData.push({ id: subDoc.id, ...subDoc.data() });
    });
    userData.compensationConsults = compensationConsultData;

    // Fetch "contractReviews" sub-collection
    const contractReviewsRef = collection(userDoc.ref, "contractReview");
    const contractReviewsSnapshot = await getDocs(query(contractReviewsRef));
    const contractReviewsData = [];
    contractReviewsSnapshot.forEach((subDoc) => {
      contractReviewsData.push({ id: subDoc.id, ...subDoc.data() });
    });
    userData.contractReview = contractReviewsData;

    users.push(userData);
  }

  // Cache the user data in session storage with timestamp
  sessionStorage.setItem(
    "userData",
    JSON.stringify({ data: users, timestamp: new Date() })
  );

  return users;
}
