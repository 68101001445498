import "@fontsource/montserrat";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import About from "./components/Pages/About/About";
import Compensation from "./components/Pages/Compensation/Compensation";
import Contact from "./components/Pages/Contact/Contact";
import Contract from "./components/Pages/Contract/Contract";
import Home from "./components/Pages/Home/Home";
import Login from "./components/Pages/Login/Login";
import Negotiation from "./components/Pages/Negotiation/Negotiation";
import Signup from "./components/Pages/Signup/Signup";
import ForgotPassword from "./components/Pages/Login/ForgotPassword";

import {
  CompensationConsultList,
  Content,
  ContractReviewList,
  EditProfile,
  Profile,
  UpdatePassword,
} from "./components/Pages/Profile/Profile";

import { Signup as DashboardSignup } from "./components/Pages/Dashboard/Signup";

import {
  Dashboard,
  Compensation as DashboardCompensation,
  Contract as DashboardContract,
  Settings as DashboardSettings,
  UserSettings as DashboardUserSettings,
} from "./components/Pages/Dashboard/Dashboard";

import { getAuth, onAuthStateChanged } from "firebase/auth";

const auth = getAuth();

function App() {
  const [user, setUser] = useState(null);

  onAuthStateChanged(auth, (si_user) => {
    if (si_user) {
      setUser(si_user);
    } else {
      setUser(null);
    }
  });

  const RoutesList = [
    {
      name: "Home",
      path: "/",
      element: <Home user={user} />,
    },
    {
      name: "Negotiation 101",
      path: "negotiation",
      element: <Negotiation />,
    },
    {
      name: "Compensation Consult",
      path: "compensation",
      element: <Compensation />,
    },
    {
      name: "Contract Review",
      path: "contract",
      element: <Contract />,
    },
  ];
  return (
    <div className="App">
      <Navbar links={RoutesList} user={user} />
      <Routes>
        {RoutesList.map((route, index) => {
          return (
            <Route key={index} path={route.path} element={route.element} />
          );
        })}
        <Route path="/about" element={<About />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/contact" element={<Contact />} />

        <Route path="/staffSignup" element={<DashboardSignup />} />
        <Route path="/dashboard" element={<Dashboard />}>
          <Route
            path="/dashboard/compensation"
            element={<DashboardCompensation />}
          />
          <Route path="/dashboard/contract" element={<DashboardContract />} />

          <Route path="/dashboard/settings" element={<DashboardSettings />} />
          <Route
            path="/dashboard/userSettings"
            element={<DashboardUserSettings />}
          />
        </Route>
        <Route path="/profile" element={<Profile />}>
          <Route path="/profile/edit" element={<EditProfile />} />
          <Route path="/profile/updatePassword" element={<UpdatePassword />} />
          <Route
            path="/profile/contractReviewList"
            element={<ContractReviewList />}
          />
          <Route
            path="/profile/compensationConsultList"
            element={<CompensationConsultList />}
          />
          <Route path="/profile/content" element={<Content />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
