import { faPlayCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { storage } from "../../../firebaseConfig";
import fetchUserDataById from "../../../helpers/fetchUserDataById";
import { updateChapterProgress } from "../../../helpers/handleChapterProgress";
import AudioPlayer from "../../AudioPlayer/AudioPlayer";
import { Oval } from "react-loader-spinner";

export default function Content() {
  const [loading, setloading] = useState(false);
  const [user, setUser] = useState(null);
  const [userData, setuserData] = useState(null);
  const [files, setFiles] = useState([]);
  const [showAudio, setShowAudio] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  const auth = getAuth();
  const navigate = useNavigate();

  const [mapping, setMapping] = useState({
    0: 2,
    1: 3,
    2: 4,
    3: 5,
    4: 6,
    5: 7,
    6: 8,
    7: 9,
    8: 10,
    9: 11,
    10: 12,
    11: 13,
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        setuserData(await fetchUserDataById(user.uid));
      } else {
        navigate("/login");
      }
    });

    // Clean up the event listener when the component unmounts
    return () => unsubscribe();
  }, [auth, navigate]);

  useEffect(() => {
    setloading(true);
    // Create a reference to the desired path
    const pathRef = ref(storage, "content/");

    // List all files in the path
    listAll(pathRef)
      .then((result) => {
        const promises = result.items.map(async (item) => {
          const downloadURL = await getDownloadURL(item);
          return { name: item.name, downloadURL };
        });

        Promise.all(promises)
          .then((fileObjects) => {
            setFiles(fileObjects);
            setloading(false);
          })
          .catch((error) => {
            setloading(false);
            alert("Error retrieving download URLs:", error);
          });
      })
      .catch((error) => {
        setloading(false);
        alert("Error retrieving files:", error);
      });
  }, []);

  const handleChapterPlayback = (chapterIndex, downloadURL) => {
    setAudioUrl(downloadURL);
    setShowAudio(true);
    updateChapterProgress(user.uid, chapterIndex);
  };

  const getDownloadUrlForCoveredChapter = (coveredChapterName) => {
    const coveredChapter = files.find(
      (file) => file.name === `${coveredChapterName}.mp3`
    );
    return coveredChapter ? coveredChapter.downloadURL : "";
  };

  return (
    <div className="mb-5">
      <h1 className="text-2xl text-left mb-10">Content</h1>
      <div>
        <h2 className="text-lg font-bold mb-2 text-left">Remaining</h2>
        {!loading &&
        files.filter(
          (file, index) => !userData.chaptersDone.includes(index + 2)
        ).length === 0 ? (
          <p>No chapters remaining!</p>
        ) : (
          <ul className="flex flex-wrap gap-x-4 gap-y-4">
            {files.map((file, index) => {
              const chapterIndex = index + 2;
              if (userData.chaptersDone.includes(chapterIndex)) {
                return null;
              }
              const matchingKey = Object.keys(mapping).find(
                (key) => mapping[key] === chapterIndex
              );
              const downloadUrl = getDownloadUrlForCoveredChapter(
                `TrueMedWorth (Chapter ${+matchingKey + 2})`
              );
              return (
                <li
                  key={chapterIndex}
                  className="flex flex-col space-y-4 border-2 border-gray w-fit p-2 py-4 hover:border-primary transition 300ms"
                  onClick={() =>
                    handleChapterPlayback(+matchingKey + 2, downloadUrl)
                  }
                >
                  <FontAwesomeIcon
                    icon={faPlayCircle}
                    className="text-6xl text-primary"
                  />
                  {/* <strong>{file.name.replace(/\.mp3$/, "")}</strong> */}
                  <strong>{`TrueMedWorth (Chapter ${
                    +matchingKey + 2
                  })`}</strong>
                </li>
              );
            })}
          </ul>
        )}
        {loading && (
          <div className="flex items-center justify-center">
            <Oval
              height={80}
              width={80}
              color="#4fa94d"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#4fa94d"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        )}
      </div>

      <div>
        <h2 className="text-lg font-bold mb-2 text-left">Covered</h2>
        {!loading &&
        files.filter((file, index) => userData.chaptersDone.includes(index + 2))
          .length === 0 ? (
          <p>No chapters covered yet!</p>
        ) : (
          <ul className="flex flex-wrap gap-x-4 gap-y-4">
            {files.map((file, index) => {
              const chapterIndex = index + 2;
              if (!userData.chaptersDone.includes(chapterIndex)) {
                return null;
              }
              const matchingKey = Object.keys(mapping).find(
                (key) => mapping[key] === chapterIndex
              );
              const downloadUrl = getDownloadUrlForCoveredChapter(
                `TrueMedWorth (Chapter ${+matchingKey + 2})`
              );
              return (
                <li
                  key={chapterIndex}
                  className="flex flex-col space-y-4 border-2 border-gray w-fit p-2 py-4 hover:border-primary transition 300ms"
                  onClick={() =>
                    handleChapterPlayback(+matchingKey + 2, downloadUrl)
                  }
                >
                  <FontAwesomeIcon
                    icon={faPlayCircle}
                    className="text-6xl text-primary"
                  />
                  {/* <strong>{file.name.replace(/\.mp3$/, "")}</strong> */}
                  <strong>{`TrueMedWorth (Chapter ${
                    +matchingKey + 2
                  })`}</strong>
                </li>
              );
            })}
          </ul>
        )}
        {loading && (
          <div className="flex items-center justify-center">
            <Oval
              height={80}
              width={80}
              color="#4fa94d"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#4fa94d"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        )}
      </div>

      {showAudio && (
        <AudioPlayer src={audioUrl} closeModal={() => setShowAudio(false)} />
      )}
    </div>
  );
}
