import { Link } from "react-router-dom";

const FooterLinkList = ({ links }) => {
  return links.map((link, index) => {
    return (
      <li
        key={index}
        className="md:mt-4 mt-2 text-white md:block inline md:mr-0 mr-4 hover:text-black transition 300ms"
      >
        <Link to={link.href}>{link.name}</Link>
      </li>
    );
  });
};

export default function Footer() {
  return (
    <footer className="flex flex-col container lg:px-40 px-5 mx-auto my-24">
      <div className="flex md:flex-row flex-col">
        <div className="w-1/3">
          <img
            src="logo.png"
            alt="TrueMed Worth Logo"
            className="md:w-1/2 xl:ml-0 md:ml-10"
          />
        </div>

        <div className="flex md:flex-row flex-col md:w-2/3 justify-around text-left">
          <ul className="md:mt-0 mt-3">
            <h3 className="text-white font-semibold">Quick Links</h3>
            <FooterLinkList
              links={[
                {
                  name: "About Us",
                  href: "/about",
                },
                {
                  name: "Contact Us",
                  href: "/contact",
                },
              ]}
            />
          </ul>
          <ul className="md:mt-0 mt-3">
            <h3 className="text-white font-semibold">TrueMedStaff</h3>
            <FooterLinkList
              links={[
                {
                  name: "TrueMedWorth@gmail.com",
                  href: "mailto:truemedworth@gmail.com",
                },
              ]}
            />
          </ul>
          <a
            href="/signup"
            className="w-fit h-fit text-center font-semibold text-primary bg-white py-2 px-6 border-2 border-transparent mr-5 md:mt-0 mt-3"
          >
            Register
          </a>
        </div>
      </div>
      <p className="text-white mt-14 container md:text-md text-sm">
        DISCLAIMER: The content created by TrueMedWorth is meant for education
        purposes only. The material presented on the website is the sole opinion
        of TrueMedWorth, its content writers, and its affiliates. It is the sole
        responsibility of the user to do their own research and to seek
        professional assistance when needed.
      </p>
    </footer>
  );
}
