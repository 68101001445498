import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDownloadURL, ref } from "firebase/storage";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { storage } from "../../../firebaseConfig";
import Footer from "../../Footer/Footer";
import Modal from "../../Modal/Modal";
import ViewportPlayer from "../../ViewportPlayer/ViewportPlayer";
import { updateChapterProgress } from "../../../helpers/handleChapterProgress";

import Thumbnail1 from "../../../assets/chapter-thumbnails/1.png";
import HeroImage from "../../../assets/negotiation-content-hero-white.png";
import UpNextCardImage from "../../../assets/up-next.png";

import Thumbnail2 from "../../../assets/chapter-thumbnails/2.png";
import Thumbnail3 from "../../../assets/chapter-thumbnails/3.png";
import Thumbnail4 from "../../../assets/chapter-thumbnails/4.png";
import Thumbnail5 from "../../../assets/chapter-thumbnails/5.png";

import "./Negotiation.css";

export default function Negotiation() {
  const [showModal, setShowModal] = useState(false);
  const [currentChapterIndex, setCurrentChapterIndex] = useState(1);
  const [sourceURL, setSourceURL] = useState(null);
  const playerRef = useRef(null);
  const navigate = useNavigate();
  const auth = getAuth();
  const [user, setUser] = useState(null);

  const thumbnails = [
    Thumbnail1,
    Thumbnail2,
    Thumbnail3,
    Thumbnail4,
    Thumbnail5,
    Thumbnail1,
    Thumbnail2,
    Thumbnail3,
    Thumbnail4,
    Thumbnail5,
    Thumbnail1,
    Thumbnail2,
    Thumbnail3,
  ];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        const lastChapter = localStorage.getItem("chapterIndex");
        if (lastChapter) {
          setCurrentChapterIndex(lastChapter);
          if (lastChapter === 1) {
            setVideoURL();
          } else {
            setAudioURL(lastChapter);
          }
        }
      }
    });

    // Clean up the event listener when the component unmounts
    return () => unsubscribe();
  }, [auth, navigate]);

  const handlePlayerRef = (ref) => {
    playerRef.current = ref;
  };

  useEffect(() => {
    if (sourceURL) {
      playerRef.current.load(); // Reload the media element when sourceURL changes
      playerRef.current.play(); // Autoplay the media element if desired

      window.scrollTo({
        top: playerRef.current.offsetTop - 200, // Scroll the page to the top position of the video player element
        behavior: "smooth",
      });
    }
  }, [sourceURL]);

  useEffect(() => {
    setVideoURL();
  }, []);

  const setVideoURL = () => {
    const gsVideoReference = ref(
      storage,
      "gs://truemed-worth.appspot.com/large-video.webm"
    );
    // Replace with the actual path to your video file
    getDownloadURL(gsVideoReference).then((url) => {
      setSourceURL(url);
    });
  };

  const setAudioURL = async (chapterIndex) => {
    const gsAudioReference = ref(
      storage,
      `gs://truemed-worth.appspot.com/content/TrueMedWorth (Chapter ${chapterIndex}).mp3`
    );
    // Replace with the actual path to your video file
    const url = await getDownloadURL(gsAudioReference);
    setSourceURL(url);
  };

  const handleChapter = (chapterIndex) => {
    if (!user) {
      setShowModal(!showModal);
    } else {
      localStorage.setItem("chapterIndex", chapterIndex);
      setCurrentChapterIndex(chapterIndex);
      if (chapterIndex === 1) {
        setVideoURL();
      } else {
        setAudioURL(chapterIndex);
        updateChapterProgress(user.uid, chapterIndex);
      }
    }
  };

  const handleNextClick = () => {
    if (!user) return navigate("/login");
    const nextChapterIndex = +currentChapterIndex + 1; // Ensure nextChapterIndex is a number
    handleChapter(nextChapterIndex);
  };

  return (
    <>
      <section
        name="hero"
        className="h-screen w-screen flex flex-col items-center justify-center"
      >
        <img
          src={HeroImage}
          alt="physicians life, chapter 1, educational content"
          className="lg:w-auto md:w-2/3 mx-auto md:px-0 px-4"
        />
      </section>
      <section
        name="main"
        className="container mx-auto xl:px-40 lg:px-20 md:px-10 text-white font-semibold"
      >
        {sourceURL && (
          <>
            <ViewportPlayer
              playerRefCallback={handlePlayerRef}
              source={sourceURL}
              chapterIndex={1}
              loop
              className="mx-auto min-h-[300px] bg-black  w-full md:px-0 px-3"
            />
            <p className="text-2xl mb-3 py-4 text-primary bg-white container w-full">
              Currently Playing: Chapter {currentChapterIndex}
            </p>
          </>
        )}

        <div
          className="relative w-full cursor-pointer mt-10"
          onClick={handleNextClick}
        >
          <img src={UpNextCardImage} alt="up next" className="mx-auto" />
          <h2 className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 md:text-5xl font-semibold">
            UP NEXT
          </h2>
        </div>
      </section>

      {showModal && (
        <Modal
          text="To continue reading subsequent chapters and access additional features, we kindly request you to create an account."
          ctaText="Create New Account"
          ctaAction={() => navigate("/signup")}
          setShowModal={setShowModal}
        />
      )}

      <section name="chapters" className="w-full mt-20 [&>*]:cursor-pointer">
        <div className="w-full overflow-x-auto flex xl:justify-center md:justify-start gap-10 py-10 lg:px-20 px-10 bg-[#FBFBFB]">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((chapter) => (
            <div key={chapter} className="relative w-fit">
              <img
                src={thumbnails[chapter - 1]}
                alt={`chapter ${chapter}`}
                onClick={() => handleChapter(chapter)}
                className={`border-4 border-${
                  currentChapterIndex === chapter ? "primary" : "transparent"
                } hover:border-primary`}
              />
              <h2 className="mt-2">Chapter {chapter}</h2>
            </div>
          ))}
        </div>
      </section>
      <section
        name="footer"
        className="flex md:flex-row flex-col container lg:px-40 md:px-20 px-4 mt-10 mx-auto"
      >
        <Footer />
      </section>
    </>
  );
}
