import {
  faCloudArrowUp,
  faFileArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, getDocs } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db, storage } from "../../../firebaseConfig";
import { formatTimestamp } from "../../../helpers/formatters";
import { Oval } from "react-loader-spinner";

export default function CompensationConsultList() {
  const [loading, setloading] = useState(false);
  const [user, setUser] = useState(null);
  const auth = getAuth();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    // Clean up the event listener when the component unmounts
    return () => unsubscribe();
  }, [auth]);

  const [compensationConsults, setcompensationConsults] = useState([]);

  useEffect(() => {
    const fetchcompensationConsults = async () => {
      setloading(true);
      setcompensationConsults([]);
      if (!user) return;
      try {
        const querySnapshot = await getDocs(
          collection(db, "users", user.uid, "compensationConsults")
        );
        let contractReviewData = {};

        querySnapshot.forEach(async (doc) => {
          const { file, ...otherData } = doc.data();

          // Retrieve downloadable file URL
          const fileRef = ref(
            storage,
            "gs://truemed-worth.appspot.com/" + file
          );
          const fileUrl = await getDownloadURL(fileRef);
          contractReviewData = { fileUrl, ...otherData };
          setcompensationConsults((current) => [
            ...current,
            contractReviewData,
          ]);
        });
        setloading(false);
      } catch (error) {
        setloading(false);
        alert(error);
      }
    };

    fetchcompensationConsults();
  }, [user]);

  return (
    <div>
      <h1 className="text-2xl text-left mb-2">Compensation Consultations</h1>
      {loading ? (
        <div className="flex items-center justify-center">
          <Oval
            height={80}
            width={80}
            color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <>
          <p className="text-left mb-10">
            {compensationConsults.length === 0
              ? "No compensation consultations found!"
              : ""}
          </p>

          <ul className="flex flex-wrap gap-x-4 gap-y-4">
            <Link
              to="/compensation"
              className="flex flex-col border-2 border-gray w-fit p-2 py-4 hover:border-primary transition 300ms"
            >
              <FontAwesomeIcon
                icon={faCloudArrowUp}
                className="text-4xl text-primary"
              />
              Upload New
            </Link>
            {compensationConsults.map((contractReview, index) => {
              return (
                <li key={index}>
                  <a
                    href={contractReview.fileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex flex-col border-2 border-gray w-fit p-2 py-4 hover:border-primary transition 300ms"
                  >
                    <FontAwesomeIcon
                      icon={faFileArrowDown}
                      className="text-4xl text-primary"
                    />
                    Uploaded: {formatTimestamp(contractReview.timeStamp)}
                  </a>
                  {/* Render other data associated with the document */}
                  <p>{contractReview.otherData}</p>
                </li>
              );
            })}
          </ul>
        </>
      )}
    </div>
  );
}
