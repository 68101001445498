import { Link } from "react-router-dom";
import BackgroundVideo from "../../../assets/background.webm";
import HomeVideo from "../../../assets/home-video.mp4";
import EmpowerIcon from "../../../assets/icon-empower.png";
import EnhanceIcon from "../../../assets/icon-enhance.png";
import NegotiateIcon from "../../../assets/icon-negotiate.png";
import TestimonialImage1 from "../../../assets/testimonial-profiles/drrichardzhu.png";
import TestimonialImage2 from "../../../assets/testimonial-profiles/dryvonnechu.png";
import TestimonialImage3 from "../../../assets/testimonial-profiles/drandrewsanchez.png";
import Footer from "../../Footer/Footer";
import InfoCard from "../../InfoCard/InfoCard";
import TestimonialCard from "../../TestimonialCard/TestimonialCard";
import ViewportPlayer from "../../ViewportPlayer/ViewportPlayer";

import "./Home.css";

export default function Home({ user }) {
  return (
    <>
      <video
        src={BackgroundVideo}
        autoPlay
        muted
        loop
        className="absolute top-0 left-0 object-cover z-10 w-screen h-screen"
      ></video>
      <section
        name="hero"
        className="h-screen flex items-center py-10 text-left relative z-20 bg-black bg-opacity-50 overflow-y-hidden"
      >
        <div className="min[1440px]:w-[40%] xl:w-[45%] lg:w-[50%] md:w-[70%] md:mr-auto md:ml-[5%] mx-5 xl:pt-5 lg:pt-20">
          <h2 className="min[1440px]:text-7xl md:text-7xl text-6xl font-semibold text-white mt-5">
            Better <span className="text-primary">Contract,</span>
          </h2>
          <h2 className="min[1440px]:text-7xl  md:text-7xl text-6xl font-semibold text-white mb-5">
            Brighter <span className="text-primary">Future</span>
          </h2>
          <p className="min[1440px]:text-3xl md:text-2xl text-xl text-white mt-8 md:mb-10 mb-5">
            Created by physicians for physicians
          </p>
          <div className="flex flex-row">
            {!user && (
              <Link
                to="/signup"
                className="w-full text-center min[1440px]:text-2xl xl:text-3xl text-2xl font-bold md:text-xl min[1440px]:py-6 min[1440px]:px-3 md:py-3 md:px-3 p-2 text-primary bg-[#FFFF00] border-2 border-transparent mr-5"
              >
                Get Started
              </Link>
            )}

            <Link
              to="/about"
              className="w-[60%] text-center xl:text-3xl text-2xl  min[1440px]:py-6 min[1440px]:px-5 md:py-3 md:px-3 p-2 border-2 text-primary border-primary mr-5"
            >
              Learn More
            </Link>
          </div>
        </div>
      </section>
      <section name="main" className="pt-10 z-10 md:px-auto px-4 text-white">
        <h2 className="font-semibold text-3xl mb-3">Welcome to TrueMedWORTH</h2>
        <p className="lg:w-2/3 md:w-auto md:px-5 m-auto md:text-xl text-lg ">
          We equip physicians with the vital knowledge, negotiation skills, and
          cost-effective contract solutions they need to advocate for their
          worth and secure the brightest version of their future. Don’t settle
          for less – negotiate your compensation and contract terms confidently
          with TrueMedWorth’s expert guidance and resources
        </p>
      </section>
      <section name="video">
        <ViewportPlayer
          source={HomeVideo}
          loop
          className="w-full container mx-auto px-[10%] mt-20"
        ></ViewportPlayer>
      </section>
      <section name="testimonials">
        <h2 className="mt-16 md:mb-auto mb-10 text-3xl font-semibold underline underline-offset-8 tracking-wider text-white">
          Testimonials
        </h2>
        <div className="">
          <div className="text-left xl:mt-32 lg:mt-20 lg:pt-20 pt-32 pb-14 flex flex-wrap lg:flex-nowrap justify-center">
            <TestimonialCard
              image={TestimonialImage1}
              text="I had the pleasure of working with TrueMedWorth in searching for a new job. I was looking for a position where I would be less of an employee and more of a partner. TrueMedWorth was amazing in using my credentials and experience to create a package that showcased myself and my career goals. The consultant helped me negotiate a superb contract and polished it afterwards to further maximize my benefit—e.g. negotiating such that I would not have to pay tail insurance upon resignation. The insight and coaching were invaluable, and they were very flexible in working with my schedule as a busy private practice doctor. The long-term value I gained in my current job was well worth the cost."
              name="Dr. Richard Zhu"
              // designation="Manager at XYZ"
            />
            <TestimonialCard
              image={TestimonialImage2}
              text="TrueMedWorth helped me successfully negotiate my non-compete clause and went above and beyond to help me negotiate with the employer such that I no longer had to pay for my malpractice tail. I would highly recommend TrueMedWorth for any new contract negotiation or contract termination. They saved me so much time, stress, and money!"
              name="Dr. Yvonne Chu"
              // designation="Manager at XYZ"
            />
            <TestimonialCard
              image={TestimonialImage3}
              text="TrueMedWorth was exactly what I needed as a PGY-3 searching for my first academic hospitalist position. Every question I didn’t know I should have carved out exactly how I proceeded with my hospital of interest prior to contract negotiation. After TrueMedWorth’s education, I felt confident in my navigating the negotiation process & felt like I was in the driver’s seat the entire time."
              name="Dr. Andrew Sanchez"
              // designation="Manager at XYZ"
            />
          </div>
        </div>
      </section>
      <section
        name="info-cards"
        className="pt-10 z-10 md:px-auto px-4 text-white"
      >
        <div className="flex md:flex-row flex-col justify-center my-14 py-10 md:space-y-0 space-y-10">
          <InfoCard
            title="Empower"
            icon={EmpowerIcon}
            text="and strategize for your future"
          />
          <InfoCard
            title="Negotiate"
            icon={NegotiateIcon}
            text="the best contract terms"
          />
          <InfoCard
            title="Enhance"
            icon={EnhanceIcon}
            text="quality of life for you and your family"
          />
        </div>
      </section>

      <Footer />
    </>
  );
}
