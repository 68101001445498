import { Link } from "react-router-dom";
// Import the functions you need from the SDKs you need
import {
  createUserWithEmailAndPassword,
  getAuth,
  updateProfile,
  sendEmailVerification,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TempProfilePic from "../../../assets/profile-pic-placeholder.png";
import { db } from "../../../firebaseConfig";

import AreaOfPractice from "../../../data/practiceAreaList.json";
import "./Signup.css";

export default function Signup() {
  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    practiceState: "",
    resident: true,
    years: "",
    speciality: "",
    email: "",
    password: "",
    confirmPassword: "",
    agreed: false,
  });

  const handleInputChange = (event) => {
    const target = event.target;
    const value =
      target.type === "checkbox"
        ? target.checked
        : target.type === "radio"
        ? target.value === "true"
        : target.value;

    const name = target.name;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const [signupPending, setSignupPending] = useState(false);
  const navigate = useNavigate();

  const handleSignup = async (event) => {
    event.preventDefault();
    setSignupPending(true);
    const auth = getAuth();
    try {
      if (formState.password !== formState.confirmPassword) {
        throw new Error("Passwords do not match");
      }

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formState.email,
        formState.password
      );
      const user = userCredential.user;
      const displayName = `${formState.firstName} ${formState.lastName}`;
      const photoURL = TempProfilePic;

      await sendEmailVerification(user);
      alert("Please check your email for the verification link!");

      updateProfile(user, { displayName, photoURL }).then(async () => {
        const userData = {
          firstName: formState.firstName,
          lastName: formState.lastName,
          practiceState: formState.practiceState,
          resident: formState.resident,
          years: formState.years,
          joiningDate: Date.now(),
          speciality: formState.speciality,
          email: formState.email,
          chaptersDone: [],
        };
        await setDoc(doc(db, "users", user.uid), userData);
        setSignupPending(false);
        navigate("/login", { state: { signupSuccess: true } });
      });
    } catch (error) {
      alert(error.message);
      setSignupPending(false);
    }
  };

  return (
    <>
      <section name="header" className="pt-20 pb-10 md:px-0 px-4">
        <img
          src="logo.png"
          alt="truemed worth logo"
          className="lg:w-1/6 md:w-1/3 w-full mx-auto py-10 md:px-0 px-4"
        />
        <h1 className="text-2xl font-bold text-primary">Create New Account</h1>
        <p className="mt-2">Get your free TrueMedWorth account now</p>
        <p className="mb-2">
          Better <span className="text-white">Contract</span>, Brighter{" "}
          <span className="text-white">Future</span>
        </p>
      </section>
      <section
        name="signup form"
        className=" bg-white w-2/3 md:px-10 px-3 mx-auto rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)]"
      >
        <form onSubmit={handleSignup} className="py-10 w-full text-left">
          <div className="flex md:flex-row flex-col gap-10 ">
            <div className="md:w-1/2 w-full space-y-5">
              <div>
                <label htmlFor="firstName" className="font-bold block">
                  First Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  className="bg-gray-200 rounded p-2 w-full"
                  onChange={handleInputChange}
                  required
                  value={formState.firstName}
                />
              </div>
              <div>
                <label htmlFor="lastName" className="font-bold block">
                  Last Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  className="bg-gray-200 rounded p-2 w-full"
                  onChange={handleInputChange}
                  required
                  value={formState.lastName}
                />
              </div>
              <div>
                <label htmlFor="practiceState" className="font-bold block">
                  State of Current Practice{" "}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="practiceState"
                  id="practiceState"
                  className="bg-gray-200 rounded p-2 w-full"
                  onChange={handleInputChange}
                  required
                  value={formState.practiceState}
                />
              </div>
              <div>
                <label className="font-bold block">
                  Resident or Fellow? <span className="text-red-500">*</span>
                </label>
                <span>
                  <input
                    type="radio"
                    id="residentYes"
                    name="resident"
                    value="true"
                    checked={formState.resident === true}
                    className="mr-4"
                    onChange={handleInputChange}
                    required
                  />
                  <label className="mr-10" htmlFor="resident-yes">
                    Yes
                  </label>
                </span>
                <span>
                  <input
                    type="radio"
                    id="residentNo"
                    name="resident"
                    value="false"
                    checked={formState.resident === false}
                    className="mr-4"
                    onChange={handleInputChange}
                    required
                  />
                  <label className="mr-10" htmlFor="resident-no">
                    No
                  </label>
                </span>
              </div>
              <div>
                <input
                  type="text"
                  maxLength="2"
                  pattern="\d*"
                  name="years"
                  id="years"
                  placeholder="If no, state the number of years of practice?"
                  className="bg-gray-200 rounded p-2 w-full"
                  onChange={handleInputChange}
                  required={formState.resident}
                  value={formState.years}
                  disabled={formState.resident}
                />
              </div>
              <div>
                <label htmlFor="speciality" className="font-bold block">
                  Area of Practice <span className="text-red-500">*</span>
                </label>
                <div className="rounded border border-1 border-gray-200">
                  <select
                    name="speciality"
                    id="speciality"
                    className="w-full p-2 rounded border-r-8 border-r-transparent"
                    onChange={handleInputChange}
                    required
                    value={formState.speciality}
                  >
                    <option value="" selected disabled hidden>
                      Select speciality
                    </option>
                    {AreaOfPractice.map((option) => (
                      <option
                        value={option.replace(/ /g, "_").toLowerCase()}
                        key={option}
                      >
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 w-full space-y-5">
              <div>
                <label htmlFor="email" className="font-bold block">
                  E-mail <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="bg-gray-200 rounded p-2 w-full"
                  onChange={handleInputChange}
                  required
                  value={formState.email}
                />
              </div>
              <div>
                <label htmlFor="password" className="font-bold block">
                  Password <span className="text-red-500">*</span>
                  <div className="text-sm text-secondary mb-2">
                    The password should contain at least 10 characters, at least
                    one number, capital letter and at least one special charcter
                  </div>
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="bg-gray-200 rounded p-2 w-full"
                  onChange={handleInputChange}
                  required
                  value={formState.password}
                />
              </div>
              <div>
                <label htmlFor="password" className="font-bold block">
                  Confirm Password <span className="text-red-500">*</span>
                </label>
                <input
                  type="password"
                  name="confirmPassword"
                  id="password"
                  className="bg-gray-200 rounded p-2 w-full"
                  onChange={handleInputChange}
                  required
                  value={formState.confirmPassword}
                />
              </div>
            </div>
          </div>

          <div className="xl:w-1/2 lg:w-full mx-auto mt-20 md:mb-10 mb-2">
            <div>
              <input
                type="checkbox"
                name="privacy"
                id="privacy"
                className="mr-2"
                onChange={handleInputChange}
                required
                value={formState.agreed}
              />
              <label
                htmlFor="privacy"
                className="font-semibold md:text-md text-sm"
              >
                By submitting this request, I agree to TrueMedWorth’s Terms of
                Use and have read and understand the Privacy Policy.
              </label>
            </div>
            <button
              type="submit"
              className={`${
                signupPending
                  ? ""
                  : "hover:bg-white hover:text-primary hover:border-primary"
              } w-full bg-primary text-white hover:cursor-pointer transition 300ms border-2 border-transparent rounded py-2 my-2`}
            >
              {signupPending ? <span className="loader"></span> : "Sign up"}
            </button>
            <p className="mt-14 md:mb-10 font-semibold text-center">
              Already have an account?{" "}
              <Link to="/login">
                <span className="text-blue-500 underline">Login Now!</span>
              </Link>
            </p>
          </div>
        </form>
      </section>
      <div className="py-10"></div>
    </>
  );
}
