import { getAuth, onAuthStateChanged, updateProfile } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AreaOfPractice from "../../../data/practiceAreaList.json";
import { db } from "../../../firebaseConfig";
import fetchUserDataById from "../../../helpers/fetchUserDataById";

export default function EditProfile() {
  const auth = getAuth();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    bio: "",
    practiceState: "",
    resident: true,
    years: "",
    speciality: "",
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        navigate("/login");
      }
    });

    // Clean up the event listener when the component unmounts
    return () => unsubscribe();
  }, [auth, navigate]);

  const [savePending, setSavePending] = useState(false);

  useEffect(() => {
    async function fetchUserData() {
      const userData = await fetchUserDataById(user.uid);
      setUserData(userData);
    }
    fetchUserData();
  }, [user]);

  const handleInputChange = (event) => {
    const target = event.target;
    const value =
      target.type === "checkbox"
        ? target.checked
        : target.type === "radio"
        ? target.value === "true"
        : target.value;
    const name = target.name;

    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSavePending(true);

    if (user !== null) {
      updateProfile(user, {
        email: userData.email,
      }).then(async () => {
        await updateDoc(doc(db, "users", user.uid), userData);
        alert("Profile updated successfully!");
        setSavePending(false);
      });
    } else {
      alert("Error: User not found!");
      setSavePending(false);
    }
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="flex font-semibold space-x-4 md:justify-start justify-end">
          <button className="bg-primary rounded-lg py-2 px-3 text-white text-sm">
            Upload new picture
          </button>

          <button className="bg-gray-300 rounded-lg py-2 px-3 text-black text-sm">
            Delete
          </button>
        </div>
        <form className="mt-10 text-left space-y-5" onSubmit={handleSubmit}>
          <div className="flex space-x-4">
            <div className="w-1/2">
              <label htmlFor="fName">First Name</label>{" "}
              <input
                type="text"
                name="firstName"
                id="firstName"
                className="w-full block border border-1 border-gray-400 rounded p-2"
                onChange={handleInputChange}
                value={userData.firstName}
              />
            </div>
            <div className="w-1/2">
              <label htmlFor="lName">Last Name</label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                onChange={handleInputChange}
                value={userData.lastName}
                className="w-full block border border-1 border-gray-400 rounded p-2"
              />
            </div>
          </div>
          <div>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              onChange={handleInputChange}
              value={userData.email}
              className="w-full block border border-1 border-gray-400 rounded p-2"
            />
          </div>
          <div>
            <label htmlFor="bio">Bio</label>
            <textarea
              name="bio"
              id="bio"
              cols="30"
              rows="5"
              onChange={handleInputChange}
              value={userData.bio}
              className="w-full block border border-1 border-gray-400 rounded p-2 resize-none"
            ></textarea>
          </div>
          {userData &&
            !(userData.role === "staff" || userData.role === "admin") && (
              <>
                <div>
                  <label htmlFor="state">State of Current Practice</label>
                  <input
                    type="text"
                    name="practiceState"
                    id="practiceState"
                    value={userData.practiceState}
                    className="w-full block border border-1 border-gray-400 rounded p-2"
                  />
                </div>
                <div>
                  <label className="font-bold block">Resident or Fellow?</label>
                  <span>
                    <input
                      type="radio"
                      id="residentYes"
                      name="resident"
                      value="true"
                      checked={userData.resident === true}
                      className="mr-4"
                      onChange={handleInputChange}
                    />
                    <label className="mr-10" htmlFor="residentYes">
                      Yes
                    </label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      id="residentNo"
                      name="resident"
                      value="false"
                      checked={userData.resident === false}
                      className="mr-4"
                      onChange={handleInputChange}
                    />
                    <label className="mr-10" htmlFor="residentNo">
                      No
                    </label>
                  </span>
                </div>
                <div>
                  <input
                    type="text"
                    maxLength="2"
                    pattern="\d*"
                    name="years"
                    id="years"
                    placeholder="If no, state the number of years of practice?"
                    className="bg-gray-200 rounded p-2 w-full"
                    onChange={handleInputChange}
                    required={!userData.resident}
                    value={userData.years}
                    disabled={userData.resident}
                  />
                </div>
                <div>
                  <label htmlFor="speciality" className="font-bold block">
                    Area of Practice
                  </label>
                  <div className="rounded border border-1 border-gray-200">
                    <select
                      name="speciality"
                      id="speciality"
                      className="w-full p-2 rounded border-r-8 border-r-transparent"
                      onChange={handleInputChange}
                      required
                      value={userData.speciality}
                    >
                      <option value="" selected disabled hidden>
                        Select speciality
                      </option>
                      {AreaOfPractice.map((option) => (
                        <option
                          value={option.replace(/ /g, "_").toLowerCase()}
                          key={option}
                        >
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </>
            )}

          <div className="text-right mt-10">
            <button
              type="submit"
              className="bg-primary rounded-lg py-2 px-3 text-white text-sm"
            >
              {savePending ? <span className="loader"></span> : "Save Profile"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
