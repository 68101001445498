export default function TestimonialCard({ image, text, name, designation }) {
  return (
    <div className="bg-[#FBFBFB] inline-block md:w-[30%] w-[90%] my-32 lg:my-0 mx-4 mt-auto relative p-5 rounded text-center">
      <img
        src={image}
        alt={name}
        className="absolute left-[50%] -translate-x-[50%] -top-[30%] rounded-full"
      />
      <p className="mt-20 h-48 overflow-y-auto">
        <span className="md:text-lg text-sm">{text}</span>
      </p>
      <p className="pt-4">
        <span className="text-primary font-semibold">{name}</span>{" "}
        {/* <span className="text-sm">{designation}</span> */}
      </p>
    </div>
  );
}
