import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { addDoc, collection } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AreaOfPractice from "../../../data/practiceAreaList.json";
import { db, storage } from "../../../firebaseConfig";
import fetchUserDataById from "../../../helpers/fetchUserDataById";

export default function Compensation() {
  const [reqPending, setReqPending] = useState(false);
  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    phone: "",
    speciality: "",
    practiceType: "",
    resident: true,
    years: "",
    practiceState: "",
    file: "",
    agreed: false,
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate("/login");
      } else {
        autofillFormData(user);
      }
    });

    // Clean up the event listener when the component unmounts
    return () => unsubscribe();
  }, []);
  const autofillFormData = async (user) => {
    const fetchedUserData = await fetchUserDataById(user.uid);
    setFormState({
      ...formState,
      phone: fetchedUserData.phone,
      speciality: fetchedUserData.speciality,
      practiceState: fetchedUserData.practiceState,
      resident: fetchedUserData.resident,
      years: fetchedUserData.years,
    });
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value =
      target.type === "checkbox"
        ? target.checked
        : target.type === "radio"
        ? target.value === "true"
        : target.value;

    const name = target.name;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleFileInput = (event) => {
    const file = event.target.files[0];

    // Check if the file is a PDF
    if (file && file.type === "application/pdf") {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
      alert("Please select a PDF file.");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!selectedFile) {
      return alert("Please upload the offer letter as a PDF file.");
    }
    setReqPending(true);
    const auth = getAuth();
    const timeStamp = Date.now();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const offerLetterRef = ref(
          storage,
          user.uid + "/compensationConsult/" + timeStamp + ".pdf"
        );
        try {
          await addDoc(
            collection(db, "users", user.uid, "compensationConsults"),
            {
              ...formState,
              timeStamp: timeStamp,
              file: `${user.uid}/compensationConsult/${timeStamp}.pdf`,
            }
          );
          uploadBytes(offerLetterRef, selectedFile)
            .then(async (snapshot) => {
              alert("Consultation request submitted successfully!");
              setReqPending(false);
              navigate("/profile/compensationConsultList");
            })
            .catch((error) => {
              alert("Error: " + error.message);
              setReqPending(false);
            });
        } catch (error) {
          alert("Error: " + error.message);
          setReqPending(false);
        }
      } else {
        navigate("/login");
      }
    });
  };

  return (
    <>
      <section name="header" className="pt-32">
        <img
          src="logo.png"
          alt="truemed worth logo"
          className="mx-auto md:px-0 px-4"
        />
        <h1 className="text-2xl mt-5">
          Better <span className="text-white">Contract</span>, Brighter{" "}
          <span className="text-white">Future</span>
        </h1>
      </section>
      <section className="consultation form pb-10 px-4">
        <form
          action=""
          className="mt-14 pt-10 bg-white xl:w-1/3 lg:w-1/2 px-10 mx-auto rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)]"
          onSubmit={handleSubmit}
        >
          <h2 className="text-3xl font-bold text-primary">
            Compensation Consultation
          </h2>
          <h3 className="text-lg">Get your free TrueMedWorth account now</h3>
          <div className="text-left py-10 space-y-4">
            <div>
              <label htmlFor="phone" className="font-bold block">
                Phone Number <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="phone"
                id="phone"
                value={formState.phone}
                onChange={handleInputChange}
                required
                maxLength="10"
                pattern="\d*"
                className="bg-gray-200 rounded p-2 w-full"
              />
            </div>
            <div>
              <label htmlFor="speciality" className="font-bold block">
                Area of Practice <span className="text-red-500">*</span>
              </label>
              <div className="rounded border border-1 border-gray-200">
                <select
                  name="speciality"
                  id="speciality"
                  className="w-full p-2 border-r-8 border-r-transparent"
                  onChange={handleInputChange}
                  value={formState.speciality}
                  required
                >
                  <option value="" selected disabled hidden>
                    Select speciality
                  </option>
                  {AreaOfPractice.map((option) => (
                    <option
                      value={option.replace(/ /g, "_").toLowerCase()}
                      key={option}
                    >
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="type" className="font-bold block">
                Type of Practice <span className="text-red-500">*</span>
              </label>
              <div className="rounded border border-1 border-gray-200">
                <select
                  name="practiceType"
                  id="practiceType"
                  className="w-full p-2 rounded border-r-8 border-r-transparent"
                  onChange={handleInputChange}
                  value={formState.practiceType}
                  required
                >
                  <option value="" selected disabled hidden>
                    Select type of practice
                  </option>
                  <option value="academic">Academic</option>
                  <option value="private_practice">Private Practice</option>
                  <option value="hospital_based">Hospital Based</option>
                  <option value="government">Government</option>
                </select>
              </div>
            </div>
            <div>
              <label className="font-bold block">
                Resident / Fellow / Renegotiation?{" "}
                <span className="text-red-500">*</span>
              </label>
              <span>
                <input
                  type="radio"
                  id="residentYes"
                  name="resident"
                  value="true"
                  checked={formState.resident === true}
                  className="mr-4"
                  onChange={handleInputChange}
                  required
                />
                <label className="mr-10" htmlFor="resident-yes">
                  Yes
                </label>
              </span>
              <span>
                <input
                  type="radio"
                  id="residentNo"
                  name="resident"
                  value="false"
                  checked={formState.resident === false}
                  className="mr-4"
                  onChange={handleInputChange}
                  required
                />
                <label className="mr-10" htmlFor="resident-no">
                  No
                </label>
              </span>
            </div>
            <div>
              <input
                type="text"
                maxLength="2"
                pattern="\d*"
                name="years"
                id="years"
                placeholder="If renegotiation, how many years?"
                className="bg-gray-200 rounded p-2 w-full"
                onChange={handleInputChange}
                required={formState.resident}
                value={formState.resident ? "" : formState.years}
                disabled={formState.resident}
              />
            </div>
            <div>
              <label className="font-bold block" htmlFor="state">
                State of Practice <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="practiceState"
                id="practiceState"
                className="bg-gray-200 rounded p-2 w-full"
                onChange={handleInputChange}
                value={formState.practiceState}
                required
              />
            </div>
            <div>
              <label className="relative w-full flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg tracking-wide border border-blue cursor-pointer hover:bg-blue text-black font-bold">
                <FontAwesomeIcon
                  icon={faCloudArrowUp}
                  className="text-4xl text-primary my-10"
                />
                {selectedFile ? (
                  <span>{selectedFile.name}</span>
                ) : (
                  <span>
                    <span className="text-primary font-bold">
                      Click to upload
                    </span>{" "}
                    Offer letter (PDF)
                  </span>
                )}

                <input
                  type="file"
                  className="hidden"
                  onChange={handleFileInput}
                />
              </label>
            </div>
            <div>
              <input
                type="checkbox"
                name="privacy"
                id="privacy"
                className="mr-2"
                required
              />
              <label htmlFor="privacy" className="font-semibold">
                By submitting this request, I agree to TrueMedWorth’s Terms of
                Use and have read and understand the Privacy Policy.
              </label>
            </div>
            <button
              type="submit"
              className="w-full bg-primary text-white hover:bg-white hover:text-primary hover:border-primary hover:cursor-pointer transition 300ms border-2 border-transparent rounded py-2"
              onChange={handleInputChange}
            >
              {reqPending ? <span className="loader"></span> : "Submit"}
            </button>
          </div>
        </form>
      </section>
    </>
  );
}
