import { Link } from "react-router-dom";
export default function InfoCard({ title, icon, text }) {
  return (
    <Link
      to="/signup"
      className="xl:w-[30%] lg:w-[25%] md:w-[30%] text-center mx-4 p-5 flex flex-col shadow-[0_3px_10px_rgb(0,0,0,0.2)] bg-white text-primary"
    >
      <h3 className="md:text-2xl font-bold">{title}</h3>
      <img src={icon} alt={title} className="m-auto" />
      <p className="text-primary md:text-lg">{text}</p>
    </Link>
  );
}
