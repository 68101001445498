import { deleteUser, getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import TempProfilePic from "../../../assets/profile-pic-placeholder.png";
import fetchUserDataById from "../../../helpers/fetchUserDataById";
import CompensationConsultList from "./CompensationConsultList";
import Content from "./Content";
import ContractReviewList from "./ContractReviewList";
import EditProfile from "./EditProfile";
import UpdatePassword from "./UpdatePassword";

const Profile = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        const fetchedUserData = await fetchUserDataById(user.uid);
        setUserData(fetchedUserData);
      } else {
        navigate("/login");
      }
    });

    // Clean up the event listener when the component unmounts
    return () => unsubscribe();
  }, [auth, navigate]);

  const handleDeleteAccount = () => {
    const response = window.confirm(
      "Are you sure you want to delete this account?"
    );
    if (response) {
      const auth = getAuth();
      const user = auth.currentUser;

      deleteUser(user)
        .then(() => {
          alert("Your account has been deleted");
          navigate("/");
        })
        .catch((error) => {
          alert(
            "An error occurred while deleting the account, please login again " +
              error.message
          );
          navigate("/login");
        });
    }
  };

  if (user) {
    return (
      <section name="profile" className="pt-32 mx-auto bg-white">
        <div className="flex gap-y-5 md:flex-row flex-col lg:px-32 md:px-10">
          <div className="md:w-1/4 w-3/4  mx-auto flex md:flex-col flex-row md:items-start items-center">
            <div className="px-10">
              {user && (
                <img
                  src={TempProfilePic}
                  alt="profile pic"
                  className="rounded-full border-2 border-gray-200 p-2"
                />
              )}
            </div>
            <nav className="text-left mt-10 md:order-last order-first w-full">
              <ul className="space-y-2 [&>li]:cursor-pointer">
                <li
                  className={
                    location.pathname === "/profile/edit" ? "font-semibold" : ""
                  }
                >
                  <Link to="/profile/edit" state={{ uid: user.uid }}>
                    Edit Profile
                  </Link>
                </li>
                <li
                  className={
                    location.pathname === "/profile/updatePassword"
                      ? "font-semibold"
                      : ""
                  }
                >
                  <Link to="/profile/updatePassword" state={{ uid: user.uid }}>
                    Password
                  </Link>
                </li>
                <li
                  className={
                    location.pathname === "/profile/contractReviewList"
                      ? "font-semibold"
                      : ""
                  }
                >
                  <Link to="/profile/contractReviewList">Contract Reviews</Link>
                </li>
                <li
                  className={
                    location.pathname === "/profile/compensationConsultList"
                      ? "font-semibold"
                      : ""
                  }
                >
                  <Link to="/profile/compensationConsultList">
                    Compensation Consultations
                  </Link>
                </li>
                {userData &&
                  !(userData.role === "staff" || userData.role === "admin") && (
                    <>
                      <li
                        className={
                          location.pathname === "/profile/content"
                            ? "font-semibold"
                            : ""
                        }
                      >
                        <Link to="/profile/content">Content</Link>
                      </li>
                    </>
                  )}

                <li className="text-red-500 border-y-2 border-gray-100 py-2 mr-5">
                  <button onClick={handleDeleteAccount}>Delete Account</button>
                </li>
              </ul>
            </nav>
          </div>
          <div className="w-3/4 mx-auto">
            <Outlet />
          </div>
        </div>
      </section>
    );
  } else {
    return null;
  }
};

export {
  CompensationConsultList,
  Content,
  ContractReviewList,
  EditProfile,
  Profile,
  UpdatePassword,
};
