import {
  getAuth,
  updatePassword,
  signInWithEmailAndPassword,
  AuthErrorCodes,
} from "firebase/auth";
import React, { useState } from "react";

const UpdatePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [savePending, setSavePending] = useState(false);

  const handleUpdatePassword = async (event) => {
    event.preventDefault();

    const auth = getAuth();
    const user = auth.currentUser;

    try {
      // Re-authenticate the user with their current password
      const credential = await signInWithEmailAndPassword(
        auth,
        user.email,
        currentPassword
      );

      // Update the user's password
      await updatePassword(credential.user, newPassword);

      // Display a success message or take appropriate action
      alert("Password updated successfully.");
    } catch (error) {
      if (error.code === AuthErrorCodes.WRONG_PASSWORD) {
        alert("Invalid current password. Please try again!");
      } else if (error.code === AuthErrorCodes.WEAK_PASSWORD) {
        alert("Password is too weak. Please try again!");
      } else {
        alert("An error occured: " + error.message);
      }
    }
  };

  return (
    <form onSubmit={handleUpdatePassword} className="text-left space-y-5">
      <div className="flex space-x-4">
        <div className="w-1/2">
          <label htmlFor="password">Current Password</label>{" "}
          <input
            name="password"
            type="password"
            className="w-full block border border-1 border-gray-400 rounded p-2"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </div>
        <div className="w-1/2">
          <label htmlFor="newPassword">New Password</label>
          <input
            name="newPassword"
            type="password"
            className="w-full block border border-1 border-gray-400 rounded p-2"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
      </div>

      <button
        type="submit"
        className="bg-primary rounded-lg py-2 px-3 text-white text-sm"
      >
        {savePending ? <span className="loader"></span> : "Update Password"}
      </button>
    </form>
  );
};

export default UpdatePassword;
