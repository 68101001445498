export default function Modal({
  text,
  ctaAction,
  ctaText,
  setShowModal,
  isPrompt,
}) {
  return (
    <div className="h-screen w-screen z-20 bg-black bg-opacity-50 fixed top-0 left-0">
      <div className="fixed inset-0  overflow-y-auto mx-auto top-1/2  -translate-y-1/2">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-2xl bg-[#161515] text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-[#161515] px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <div className="mt-2">
                    <p className="text-lg text-white">{text}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-[#161515] px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md  px-3 py-2 text-sm font-semibold text-primary shadow-sm sm:ml-3 sm:w-auto"
                onClick={() => ctaAction()}
              >
                {ctaText}
              </button>
              {!isPrompt && (
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm sm:mt-0 sm:w-auto"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
