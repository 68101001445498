import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Firebase configuration object
const firebaseConfig = {
  apiKey: "AIzaSyASZSk-9LmH9_MUXslQfhGvsfeXmszEFms",
  authDomain: "truemed-worth.firebaseapp.com",
  projectId: "truemed-worth",
  storageBucket: "truemed-worth.appspot.com",
  messagingSenderId: "411172773216",
  appId: "1:411172773216:web:86eb483e29f9a445179205",
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Get Firestore instance
const db = getFirestore(app);

const storage = getStorage(app);

export { app, db, storage, firebaseConfig };
