import React, { useEffect, useRef, useState } from "react";
import fetchAllUserData from "../../../helpers/fetchAllUserData";
import {
  formatTextVariables,
  formatTimestamp,
} from "../../../helpers/formatters";
import UserDetails from "./UserDetails";
import { Oval } from "react-loader-spinner";

export default function Contract() {
  const [showModal, setShowModal] = useState(false);
  const [loading, setloading] = useState(false);
  const [userDetailTarget, setUserDetailTarget] = useState({});
  const [requestObject, setConsultRequestObject] = useState({});
  const [filterValue, setFilterValue] = useState("all");
  const numberOfRequests = useRef(0);

  const fetchUsersWithCompensationRequest = async () => {
    setloading(true);
    const userDataSnapshot = await fetchAllUserData();
    const users = [];
    numberOfRequests.current = 0;

    userDataSnapshot.forEach(async (userDoc) => {
      if (userDoc.contractReview.length > 0) {
        numberOfRequests.current += userDoc.contractReview.length;
        users.push(userDoc);
      }
    });
    setloading(false);
    return users;
  };

  const [userData, setUserData] = useState([]);

  const showUserDetails = (user, reviewRequest) => {
    setShowModal(true);
    setUserDetailTarget(user);
    setConsultRequestObject(reviewRequest);
  };
  const fetchUserData = async () => {
    try {
      const fetchedUserData = await fetchUsersWithCompensationRequest();
      setUserData(fetchedUserData);
    } catch (error) {
      alert("Error fetching users:", error);
    }
  };
  useEffect(() => {
    fetchUserData();
  }, []);

  const handleDropdownChange = (e) => {
    setFilterValue(e.target.value);
  };

  const filterFunction = (consultRequest) => {
    switch (filterValue) {
      case "all":
        return true;
      case "reviewed":
        return consultRequest.reviewed;
      case "non-reviewed":
        return !consultRequest.reviewed;
      default:
        return false;
    }
  };

  const sortByDate = () => {
    return (a, b) =>
      b.contractReview[0].timeStamp - a.contractReview[0].timeStamp;
  };

  const handleModalClose = () => {
    setShowModal(false);
    fetchUserData();
  };

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center">
          <Oval
            height={80}
            width={80}
            color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <div>
          {showModal && (
            <UserDetails
              user={userDetailTarget}
              type="reviewRequest"
              requestObject={requestObject}
              close={() => handleModalClose()}
            />
          )}
          <nav className="mx-auto flex flex-wrap px-4 flex-col md:flex-row items-center"></nav>
          <section className="text-gray-600 body-font">
            <div className="pb-12 mx-auto">
              <div className="w-full mx-auto overflow-auto">
                <table className="table-auto w-full text-left whitespace-no-wrap ">
                  <thead>
                    <tr>
                      <th className="px-4 py-5 title-font tracking-wider font-medium text-gray-900 text-sm bg-[#5CBFB1] rounded-tl rounded-bl">
                        Username
                      </th>
                      <th className="px-4 py-5 title-font tracking-wider font-medium text-gray-900 text-sm bg-[#5CBFB1]">
                        Email
                      </th>
                      <th className="px-4 py-5 title-font tracking-wider font-medium text-gray-900 text-sm bg-[#5CBFB1]">
                        Type of Practice
                      </th>
                      <th className="px-4 py-5 title-font tracking-wider font-medium text-gray-900 text-sm bg-[#5CBFB1]">
                        Speciality
                      </th>
                      <th className="title-font tracking-wider font-medium text-gray-900 text-sm bg-[#5CBFB1] ">
                        Date of Upload
                      </th>
                      <th className="w-15 title-font tracking-wider font-medium text-gray-900 text-sm bg-[#5CBFB1] rounded-tr rounded-br">
                        <div>
                          <label htmlFor="dropdown">Filter: </label>
                          <select
                            id="dropdown"
                            value={filterValue}
                            onChange={handleDropdownChange}
                            className="p-2 rounded-lg bg-white"
                          >
                            <option value="all">All</option>
                            <option value="reviewed">Reviewed</option>
                            <option value="non-reviewed">Non-Reviewed</option>
                          </select>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {userData
                      // Sort userData based on the sorting function returned by getSortingFunction
                      .sort(sortByDate())
                      .map((user) => {
                        return user.contractReview
                          .filter((reviewRequest) =>
                            filterFunction(reviewRequest)
                          )
                          .map((reviewRequest, index) => {
                            return (
                              <tr key={index}>
                                <td className="px-4 py-6">
                                  {user.firstName + " " + user.lastName}
                                </td>
                                <td className="px-4 py-6">{user.email}</td>
                                <td className="px-4 py-6">
                                  {formatTextVariables(
                                    reviewRequest.practiceType
                                  )}
                                </td>
                                <td className="px-4 py-6">
                                  {formatTextVariables(
                                    reviewRequest.speciality
                                  )}
                                </td>
                                <td>
                                  {formatTimestamp(reviewRequest.timeStamp)}
                                </td>
                                <td className="">
                                  <button
                                    className="mx-auto flex items-center justify-between border-2 rounded-md p-2"
                                    onClick={() =>
                                      showUserDetails(user, reviewRequest)
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height="1em"
                                      viewBox="0 0 576 512"
                                      className="mr-2"
                                    >
                                      <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                                    </svg>
                                    View Details
                                  </button>
                                </td>
                              </tr>
                            );
                          });
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
            Showing {numberOfRequests.current} results
          </div>
        </div>
      )}
    </>
  );
}
