import { Link } from "react-router-dom";
// Import the functions you need from the SDKs you need
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TempProfilePic from "../../../assets/profile-pic-placeholder.png";
import { db } from "../../../firebaseConfig";

const Signup = () => {
  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const [signupPending, setSignupPending] = useState(false);
  const navigate = useNavigate();

  const handleSignup = async (event) => {
    event.preventDefault();
    setSignupPending(true);
    if (formState.password !== formState.confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    const auth = getAuth();
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formState.email,
        formState.password
      );
      const user = userCredential.user;
      const displayName = `${formState.firstName} ${formState.lastName}`;
      const photoURL = TempProfilePic;

      await sendEmailVerification(user);
      alert("Please check your email for the verification link!");

      updateProfile(user, { displayName, photoURL }).then(async () => {
        const userData = {
          firstName: formState.firstName,
          lastName: formState.lastName,
          email: formState.email,
          role: "staff",
          approved: false,
        };
        await setDoc(doc(db, "staff", user.uid), userData);
        setSignupPending(false);
        navigate("/login", { state: { signupSuccess: true } });
      });
    } catch (error) {
      alert(error.message);
      setSignupPending(false);
    }
  };

  return (
    <div className="min-h-screen">
      <section name="header" className="pt-40 pb-10 md:px-0 px-4">
        <h1 className="text-2xl font-bold text-white">
          Create New Staff Account
        </h1>
      </section>
      <section
        name="signup form"
        className=" bg-white w-2/3 md:px-10 px-3 mx-auto rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)]"
      >
        <form onSubmit={handleSignup} className="py-10 w-full text-left">
          <div className="flex md:flex-row flex-col gap-10 ">
            <div className="md:w-1/2 w-full space-y-5">
              <div>
                <label htmlFor="firstName" className="font-bold block">
                  First Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  className="bg-gray-200 rounded p-2 w-full"
                  onChange={handleInputChange}
                  required
                  value={formState.firstName}
                />
              </div>
              <div>
                <label htmlFor="lastName" className="font-bold block">
                  Last Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  className="bg-gray-200 rounded p-2 w-full"
                  onChange={handleInputChange}
                  required
                  value={formState.lastName}
                />
              </div>
              <div>
                <label htmlFor="email" className="font-bold block">
                  E-mail <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="bg-gray-200 rounded p-2 w-full"
                  onChange={handleInputChange}
                  required
                  value={formState.email}
                />
              </div>
            </div>
            <div className="md:w-1/2 w-full space-y-5">
              <div>
                <label htmlFor="password" className="font-bold block">
                  Password <span className="text-red-500">*</span>
                  <div className="text-sm text-secondary mb-2">
                    The password should contain at least 10 characters, at least
                    one number, capital letter and at least one special charcter
                  </div>
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="bg-gray-200 rounded p-2 w-full"
                  onChange={handleInputChange}
                  required
                  value={formState.password}
                />
              </div>
              <div>
                <label htmlFor="password" className="font-bold block">
                  Confirm Password <span className="text-red-500">*</span>
                </label>
                <input
                  type="password"
                  name="confirmPassword"
                  id="password"
                  className="bg-gray-200 rounded p-2 w-full"
                  onChange={handleInputChange}
                  required
                  value={formState.confirmPassword}
                />
              </div>
              <div>
                <button
                  type="submit"
                  className={`${
                    signupPending
                      ? ""
                      : "hover:bg-white hover:text-primary hover:border-primary"
                  } w-full bg-primary text-white hover:cursor-pointer transition 300ms border-2 border-transparent rounded py-2 my-2`}
                >
                  {signupPending ? <span className="loader"></span> : "Sign up"}
                </button>
              </div>
            </div>
          </div>

          <div className="xl:w-1/2 lg:w-full mx-auto mt-20 md:mb-10 mb-2">
            <p className="mt-14 md:mb-10 font-semibold text-center">
              Already have an account?{" "}
              <Link to="/login">
                <span className="text-blue-500 underline">Login Now!</span>
              </Link>
            </p>
          </div>
        </form>
      </section>
      <div className="py-10"></div>
    </div>
  );
};

export { Signup };
