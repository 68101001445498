import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import HeaderImage from "../../../assets/about-header.png";
import EmpowerIcon from "../../../assets/icon-empower.png";
import EnhanceIcon from "../../../assets/icon-enhance.png";
import NegotiateIcon from "../../../assets/icon-negotiate.png";
import Footer from "../../Footer/Footer";
import InfoCard from "../../InfoCard/InfoCard";

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section
        name="hero"
        className="flex flex-col items-center md:justify-center justify-start pt-32 px-4"
      >
        <h1 className="font-bold underline underline-offset-4 md:text-4xl text-2xl mb-8">
          "Your <span className="text-white">Worth</span>, Your{" "}
          <span className="text-white">Future</span>, Your{" "}
          <span className="text-white">Choice</span>."
        </h1>
        <div className="relative">
          <img
            src={HeaderImage}
            alt="young executives"
            className="mx-auto z-20 relative"
            style={{ filter: "drop-shadow(5px 5px 5px rgba(0,0,0,0.2))" }}
          />
          <p className="md:text-xl lg:w-2/3 md:px-10 py-5 mx-auto text-white text-left rounded-2xl bg-primary z-10">
            "We believe the days of predetermined salaries based on geography
            and post-graduate level are officially over. It's time for better
            contracts, brighter futures, and the kind of community-driven
            support that every physician needs to thrive."
            <br />
            <br />
            As young graduates and attending physicians, negotiating our
            compensation and contract terms can be the most awkward, confusing,
            and completely overwhelming aspect of our medical career.
            <br />
            <br />
            Due to the culture of passive acceptance we develop during our
            residency and fellowship training, we're not prepared to
            successfully advocate for our worth and simply accept the salary
            we're given.
            <br />
            <br />
            At TrueMedWorth, our mission is to deliver the vital knowledge,
            negotiation skills, and cost-effective contract solutions you need
            to:
          </p>
        </div>
        <div className="flex md:flex-row flex-col justify-center my-14 py-10 md:space-y-0 space-y-10">
          <InfoCard
            title="Empower"
            icon={EmpowerIcon}
            text="and strategize for your future"
          />
          <InfoCard
            title="Negotiate"
            icon={NegotiateIcon}
            text="the best contract terms"
          />
          <InfoCard
            title="Enhance"
            icon={EnhanceIcon}
            text="quality of life for your and your family"
          />
        </div>
      </section>
      <section
        name="faq"
        className="container mx-auto xl:px-40 px-4 text-white"
      >
        <h1 className="text-2xl font-bold text-left my-10 text-white">
          Frequently Asked Questions
        </h1>
        <Accordion allowZeroExpanded={true} className="space-y-8">
          <AccordionItem className="border border-1 border-white">
            <AccordionItemHeading className="p-2 text-lg text-left">
              <AccordionItemButton className="flex items-center">
                <div className="p-2 mr-2 text-xl text-white">
                  <FontAwesomeIcon icon={faPlus} />
                </div>
                How much does TrueMedWorth cost to join?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p className="text-left py-2 ml-12 pr-5">
                TrueMedWorth is free to join for physicians, with access to free
                educational content and modules to effectively practice
                negotiation skills.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem className="border border-1 border-white">
            <AccordionItemHeading className="p-2 text-lg text-left">
              <AccordionItemButton className="flex items-center">
                <div className="p-2 mr-2 text-xl text-white">
                  <FontAwesomeIcon icon={faPlus} />
                </div>
                What is TrueMedWorth?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p className="text-left py-2 ml-12 pr-5">
                TrueMedWorth is a platform for physicians to provide free
                educational content to help physicians make informed and
                empowered decisions for their future and negotiate the best
                contract terms and compensation. TrueMedWorth also provides
                modules to practice negotiation skills, as well as additional
                resources, including consultation on compensation and potential
                offers, guidance on potential areas of negotiation, access to
                fair market value experts, and legal contract reviews in a
                seamless, cost-effective way for its members.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem className="border border-1 border-white">
            <AccordionItemHeading className="p-2 text-lg text-left">
              <AccordionItemButton className="flex items-center">
                <div className="p-2 mr-2 text-xl text-white">
                  <FontAwesomeIcon icon={faPlus} />
                </div>
                What is TrueMedWorth mission?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p className="text-left py-2 ml-12 pr-5">
                TrueMedWorth’s mission is to provide the vital knowledge,
                negotiation skills, and resources every physician needs to get
                paid their worth. We believe all physicians should receive the
                compensation terms and compensation package they truly deserve.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem className="border border-1 border-white">
            <AccordionItemHeading className="p-2 text-lg text-left">
              <AccordionItemButton className="flex items-center">
                <div className="p-2 mr-2 text-xl text-white">
                  <FontAwesomeIcon icon={faPlus} />
                </div>
                Why was TrueMedWorth created?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p className="text-left py-2 ml-12 pr-5">
                TrueMedWorth was created by physicians who recognize the
                challenges faced when negotiating compensation and contract
                terms. As physicians with years of medical school and training,
                we are not prepared to successfully advocate our worth and
                simply accept the salary and conditions that we are given. The
                medical system is complex and built around a compensation plan
                that can be difficult to understand and overwhelming to navigate
                alone. TrueMedWorth’s vision is to make the journey of building
                a brighter future in medicine as seamless, empowered, and
                sustainable as possible.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem className="border border-1 border-white">
            <AccordionItemHeading className="p-2 text-lg text-left">
              <AccordionItemButton className="flex items-center">
                <div className="p-2 mr-2 text-xl text-white">
                  <FontAwesomeIcon icon={faPlus} />
                </div>
                How to get registered for TrueMedWorth
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p className="text-left py-2 ml-12 pr-5">
                You should go to Truemedworth.com and register an account.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </section>
      <Footer />
      <div className="py-10"></div>
    </>
  );
}
