import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import fetchUserDataById from "./fetchUserDataById";

async function updateChapterProgress(uid, chapterIndex) {
  console.log(uid, chapterIndex);
  try {
    let userData = await fetchUserDataById(uid);
    userData.chaptersDone = userData.chaptersDone || [];
    if (!userData.chaptersDone.includes(chapterIndex)) {
      userData.chaptersDone.push(chapterIndex);
      await setDoc(doc(db, "users", uid), userData);
    }
  } catch (err) {
    alert(
      "An error occurred while updating your chapter progress: " + err.message
    );
  }
}

export { updateChapterProgress };
