import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig"; // Replace with your Firebase configuration import
export default async function fetchUserDataById(docId) {
  const usersDocRef = doc(db, "users", docId);
  const usersDocSnap = await getDoc(usersDocRef);
  if (usersDocSnap.data()) {
    return usersDocSnap.data();
  } else {
    try {
      const staffDocRef = doc(db, "staff", docId);
      const staffDocSnap = await getDoc(staffDocRef);
      return staffDocSnap.data();
    } catch (err) {
      console.error("Error fetching user data: ", err);
    }
  }
}
