import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addDoc, collection } from "firebase/firestore";
import { useState } from "react";
import { db } from "../../../firebaseConfig";

export default function Contact() {
  const [reqPending, setReqPending] = useState(false);
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const target = event.target;
    const value =
      target.type === "checkbox"
        ? target.checked
        : target.type === "radio"
        ? target.value === "true"
        : target.value;

    const name = target.name;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setReqPending(true);
    try {
      await addDoc(collection(db, "contactForm"), formState);
      alert("Your message has been submitted!");
      setFormState({
        name: "",
        email: "",
        message: "",
      });
      setReqPending(false);
    } catch (err) {
      alert("An error occurred: " + err.message);
      setReqPending(false);
    }
  };

  return (
    <>
      <div className="bg-white w-screen h-screen absolute top-0 left-0"></div>
      <div className="bg-white mt-40 py-10 relative lg:block flex flex-col ">
        <div className="lg:w-[310px] w-2/3 mx-auto order-2 bg-primary text-white text-left px-5 py-8 mt-10 rounded lg:absolute block xl:left-52 lg:left-14 top-[20%] bottom-[20%] shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
          <h1 className="text-3xl font-bold">Contact Info</h1>
          <ul className="mt-8 space-y-5">
            <li className="flex items-center">
              <FontAwesomeIcon icon={faEnvelope} className="text-xl ml-1" />
              <div className="mx-4 md:text-base text-sm">
                truemedworth@gmail.com
              </div>
            </li>
          </ul>
        </div>
        <form
          className="xl:w-1/2 lg:w-4/5 w-2/3 px-10 mx-auto py-10 xl:pl-48 lg:pl-80 pr-10 text-left rounded space-y-4 shadow-[0_3px_10px_rgb(0,0,0,0.2)]"
          onSubmit={handleSubmit}
        >
          <div>
            <h1 className="text-3xl text-primary font-bold">Send a Message</h1>
            <p className="text-sm">
              Any question or remarks? Just send us a message.
            </p>
          </div>
          <div>
            <label htmlFor="name" className="font-bold">
              Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Enter your name"
              value={formState.name}
              onChange={handleInputChange}
              required
              className="bg-gray-200 rounded p-2 w-full"
            />
          </div>
          <div>
            <label htmlFor="email" className="font-bold">
              Email address
            </label>
            <input
              type="text"
              name="email"
              id="email"
              placeholder="Enter your email"
              value={formState.email}
              onChange={handleInputChange}
              required
              className="bg-gray-200 rounded p-2 w-full"
            />
          </div>
          <div>
            <label htmlFor="message" className="font-bold">
              Message
            </label>
            <textarea
              name="message"
              id="message"
              rows={4}
              placeholder="Enter your message here"
              value={formState.message}
              onChange={handleInputChange}
              required
              className="bg-gray-200 rounded p-2 w-full resize-none"
            />
          </div>
          <button
            type="submit"
            className="w-fit px-8 bg-primary text-white hover:bg-white hover:text-primary hover:border-primary hover:cursor-pointer transition 300ms border-2 border-transparent rounded py-2"
          >
            {reqPending ? <span className="loader"></span> : "Submit"}
          </button>
        </form>
      </div>
    </>
  );
}
