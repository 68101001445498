import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import TempProfilePic from "../../assets/profile-pic-placeholder.png";
import fetchUserDataById from "../../helpers/fetchUserDataById";
import ProfileFrame from "./ProfileFrame";

export default function Navbar({ links }) {
  const [colorChange, setColorchange] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [navbarColor, setNavbarColor] = useState("white");
  const [userData, setUserData] = useState(null);
  const [toggleProfileFrame, setToggleProfileFrame] = useState(false);
  const [user, setUser] = useState(null);

  const location = useLocation();

  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else setUser(null);
    });

    // Clean up the event listener when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    setNavbarColor(
      location.pathname === "/"
        ? "primary"
        : location.pathname.startsWith("/profile/") ||
          location.pathname === "/contact" ||
          location.pathname.startsWith("/dashboard/")
        ? "black"
        : "white"
    );
  }, [location]);

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      alert("Error signing out: " + error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (user) setUserData(await fetchUserDataById(user.uid));
    }
    fetchData();
  }, [user]);

  window.addEventListener("scroll", changeNavbarColor);

  return (
    <nav
      className={`${
        colorChange
          ? "bg-opacity-50 text-white"
          : "bg-opacity-0 text-" + navbarColor
      } bg-black fixed w-full xl:py-4 z-30 transition 300ms flex justify-between items-center`}
      style={colorChange ? { backdropFilter: "blur(5px)" } : {}}
    >
      <Link to="/" className={`${showMobileMenu ? "hidden" : "block"}`}>
        <img
          src="/logo.png"
          alt="truemedworth logo"
          className={`${
            colorChange ? "md:w-[100px]" : "md:w-[150px]"
          } w-[70px] mr-auto xl:mt-0 md:ml-4 md:mt-4 ml-2 my-2 transition 300ms`}
        />
      </Link>

      <div>
        <ul className="w-fit ml-auto xl:block hidden">
          {links.map((link, index) => {
            return (
              <li
                key={index}
                className={`xl:text-lg lg:text-md font-semibold inline-block mr-10`}
              >
                <Link to={link.path}>{link.name}</Link>
              </li>
            );
          })}
          {user && userData ? (
            <span className="inline-flex space-x-4 items-center align-middle pr-5">
              <li className="xl:text-xl lg:text-lg font-semibold ">
                <div onMouseEnter={() => setToggleProfileFrame(true)}>
                  <div className="w-[35px] relative ">
                    <img
                      src={TempProfilePic}
                      className="cursor-pointer bg-white rounded-full"
                      alt="profile pic"
                    />
                    {toggleProfileFrame && (
                      <ProfileFrame
                        user={user}
                        onMouseLeave={() => setToggleProfileFrame(false)}
                      />
                    )}
                  </div>
                </div>
              </li>
            </span>
          ) : (
            <span>
              <li className="text-white xl:text-md lg:text-sm font-semibold inline-block">
                <Link
                  to="/signup"
                  className="w-full text-center py-2 px-6 bg-white text-primary border-2 border-transparent mr-5"
                >
                  Get Started
                </Link>
              </li>
              <li className="text-white xl:text-md lg:text-sm font-semibold inline-block">
                <a
                  href="/login"
                  className="w-full text-center text-white  py-2 px-10 border-2 border-white mr-5"
                >
                  Log in
                </a>
              </li>
            </span>
          )}
        </ul>
      </div>

      <ul
        className={`${
          showMobileMenu ? "block bg-opacity-100" : "hidden bg-opacity-0"
        } w-full bg-[#252525] text-center py-10 overflow-hidden transition 300ms`}
      >
        {links.map((link, index) => {
          return (
            <li
              key={index}
              className="text-white text-xl my-8 w-full font-semibold block mr-10"
            >
              <Link to={link.path}>{link.name}</Link>
            </li>
          );
        })}
        {user && userData ? (
          <>
            <li className="text-white text-xl my-8 w-full font-semibold block mr-10">
              <Link to="/profile/edit">Profile</Link>
            </li>
            <li className="text-white text-xl my-8 w-full font-semibold block mr-10">
              <button onClick={handleSignOut}>Signout</button>
            </li>
          </>
        ) : (
          <span>
            <li className="text-white text-xl my-8 w-full font-semibold block">
              <Link
                to="/signup"
                className="w-full text-center text-white py-2 px-6 bg-primary border-2 border-transparent "
              >
                Get Started
              </Link>
            </li>
            <li className="text-white text-xl my-8 w-full font-semibold block">
              <a
                href="/login"
                className="w-full text-center text-white py-2 px-10 border-2 border-white "
              >
                Log in
              </a>
            </li>
          </span>
        )}
      </ul>

      <button
        type="button"
        className={`${showMobileMenu ? "top-0" : "top-1/2 -translate-y-1/2"}
         absolute right-0 w-full inline-flex justify-end p-2 ml-3 text-sm xl:hidden`}
        aria-controls="navbar-default"
        aria-expanded="false"
        onClick={() => setShowMobileMenu(!showMobileMenu)}
      >
        <span className="sr-only">Open main menu</span>
        <svg
          className="w-9 h-9"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
    </nav>
  );
}
