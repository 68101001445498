import {
  AuthErrorCodes,
  getAuth,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import fetchUserDataById from "../../../helpers/fetchUserDataById";

export default function Signup() {
  const location = useLocation();
  const signupSuccess = location.state?.signupSuccess;

  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const auth = getAuth();
    const { email, password } = formState;
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      const userData = await fetchUserDataById(user.uid);

      // If standard user, and verified -> route to content
      if (user.emailVerified) {
        navigate("/negotiation");
      }
      // If not verified, but approved staff -> route to dashboard
      else if (userData.approved) {
        navigate("/dashboard/compensation");
      }
      // If neither standard verified user, nor approved staff -> logout
      else {
        if (!user.emailVerified) {
          await sendEmailVerification(user);
          throw new Error(
            "Email not verified! A verification link has been re-sent. Please check your email!"
          );
        }
        throw new Error(
          !userData.approved
            ? "Your staff account is not approved! Please request the administrator for approval."
            : "Unverified Account! Please click the verification link sent to your email address!"
        );
      }
    } catch (error) {
      let errorMessage = "";
      switch (error.code) {
        case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
          errorMessage =
            "Please check your email for an existing verification link!";
          break;
        default:
          errorMessage = "An error occured: " + error.message;
      }
      signOut(auth);
      alert(errorMessage);
    }
  };

  return (
    <>
      <section
        name="login form"
        className="md:mt-0 mt-20 pt-32 h-screen flex items-center justify-center"
      >
        <div className="lg:w-1/3 md:w-1/2 md:px-0 px-4">
          {signupSuccess && (
            <p className="text-green-700 mb-4">
              Signup successful! Please check the verification link sent to your
              email to activate your account!
            </p>
          )}
          <div className=" bg-white px-10 pt-10 mx-auto rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
            <h1 className="font-bold text-primary text-2xl">Welcome</h1>
            <p className="text-xl">Login to continue to TrueMedWorth</p>
            <form
              onSubmit={handleFormSubmit}
              className="py-10 w-full text-left space-y-5"
            >
              <div>
                <label htmlFor="email" className="font-bold block">
                  E-mail <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="bg-gray-200 rounded p-2 w-full"
                  onChange={handleInputChange}
                  value={formState.email}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="font-bold flex justify-between"
                >
                  <div>
                    Password <span className="text-red-500">*</span>
                  </div>
                  <Link to="/forgotPassword" className="text-right">
                    <span className="text-primary">Forgot Password?</span>
                  </Link>
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="bg-gray-200 rounded p-2 w-full"
                  onChange={handleInputChange}
                  value={formState.password}
                  required
                />
              </div>
              <div>
                <input
                  type="checkbox"
                  name="rememberMe"
                  id="rememberMe"
                  className="mr-2"
                  onChange={handleInputChange}
                  checked={formState.rememberMe}
                />
                <label htmlFor="rememberMe" className="font-semibold">
                  Remember me
                </label>
              </div>
              <input
                type="submit"
                value="Login"
                className="w-full bg-primary text-white hover:bg-white hover:text-primary hover:border-primary hover:cursor-pointer transition 300ms border-2 border-transparent rounded py-2"
              />
            </form>
          </div>
          <p className="mt-14 mb-10 pb-20 font-semibold text-center">
            Don't have an account?{" "}
            <Link to="/signup">
              <span className="text-blue-500 underline">Register Now!</span>
            </Link>
          </p>
        </div>
        <div className="py-10"></div>
      </section>
    </>
  );
}
