import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useState } from "react";
import { db, storage } from "../../../firebaseConfig";
import { formatTextVariables } from "../../../helpers/formatters";
import { useLocation } from "react-router-dom";

export default function UserDetails({ user, type, requestObject, close }) {
  const auth = getAuth();
  const location = useLocation();
  const [selectedFile, setSelectedFile] = useState(null);
  const [reqPending, setReqPending] = useState(false);
  const [dynamicRequestObject, setDynamicRequestObject] =
    useState(requestObject);

  const getDownloadLink = async (file) => {
    const fileRef = ref(storage, "gs://truemed-worth.appspot.com/" + file);
    const downloadURL = await getDownloadURL(fileRef);
    return downloadURL;
  };

  const handleDownload = (file) => {
    const link = document.createElement("a");
    getDownloadLink(file).then((downloadURL) => {
      link.href = downloadURL;
      link.target = "_blank";
      link.click();
    });
  };

  const cacheUpdateFunction = async (updatedDoc) => {
    try {
      const requestCollectionKey =
        type === "compensationRequest"
          ? "compensationConsults"
          : "contractReview";
      let cacheData = JSON.parse(sessionStorage.getItem("userData"));
      if (cacheData) {
        const targetUserIndex = cacheData.data.findIndex(
          (targetUser) => targetUser.id === user.id
        );
        const targetRequestIndex = cacheData.data[targetUserIndex][
          requestCollectionKey
        ].findIndex((request) => request.id === requestObject.id);

        cacheData.data[targetUserIndex][requestCollectionKey][
          targetRequestIndex
        ] = updatedDoc;

        setDynamicRequestObject(
          cacheData.data[targetUserIndex][requestCollectionKey][
            targetRequestIndex
          ]
        );
        sessionStorage.setItem(
          "userData",
          JSON.stringify({ data: cacheData.data, timestamp: new Date() })
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleUpload = () => {
    setReqPending(true);
    onAuthStateChanged(auth, async (staff) => {
      if (staff) {
        const feedbackPath = `staff/${staff.uid}/feedbacks/${requestObject.file}`;
        const feedbackRef = ref(storage, feedbackPath);
        const userDoc = doc(
          db,
          "users",
          user.id,
          type === "compensationRequest"
            ? "compensationConsults"
            : "contractReview",
          requestObject.id
        );
        const staffDoc = doc(
          db,
          "staff",
          staff.uid,
          "feedbacks",
          user.id,
          type === "compensationRequest"
            ? "compensationConsults"
            : "contractReview",
          requestObject.id
        );
        uploadBytes(feedbackRef, selectedFile).then(async () => {
          try {
            await setDoc(userDoc, {
              ...requestObject,
              reviewed: true,
              reviewLink: feedbackPath,
            });
            cacheUpdateFunction({
              ...requestObject,
              reviewed: true,
              reviewLink: feedbackPath,
            });
            await setDoc(staffDoc, {
              requestID: requestObject.id,
              reviewLink: feedbackPath,
              timeStamp: Date.now(),
            });
            alert("Your feedback has been successfully uploaded!");
            setReqPending(false);
          } catch (err) {
            setReqPending(false);
            alert(err.message);
            console.error(err);
          }
        });
      }
    });
  };

  const handleFileInput = (event) => {
    const file = event.target.files[0];

    // Check if the file is a PDF
    if (file && file.type === "application/pdf") {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
      alert("Please select a PDF file.");
    }
  };

  return (
    <div className="w-screen h-screen bg-black bg-opacity-50 top-0 left-0 fixed z-40">
      <div className="w-fit m-auto h-fit fixed bg-white z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <button
          className="bg-[#5cbfb1] flex items-center w-40 h-8 justify-center mt-12 "
          onClick={() => close()}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          <span className="font-semibold ml-4">User Details</span>
        </button>
        <table className="my-6 table-auto w-full">
          <tbody>
            <tr>
              <td className="text-[#5cbfb1] font-semibold text-left py-2 md:px-8">
                Name:
              </td>
              <td className="text-right md:px-8 py-2">
                {user.firstName + " " + user.lastName}
              </td>
            </tr>
            <tr>
              <td className="text-[#5cbfb1] text-left font-semibold py-2 md:px-8">
                Email:
              </td>
              <td className="text-right md:px-8 py-2">{user.email}</td>
            </tr>
            <tr>
              <td className="text-[#5cbfb1] text-left font-semibold py-2 md:px-8">
                Phone:
              </td>
              <td className="text-right md:px-8 py-2">
                {location.pathname === "/dashboard/compensation"
                  ? user.compensationConsults[0].phone &&
                    user.compensationConsults[0].phone
                  : user.contractReview[0].phone &&
                    user.contractReview[0].phone}
              </td>
            </tr>
            {type === "compensationRequest" ? (
              <tr>
                <td className="text-[#5cbfb1] font-semibold text-left py-2 md:px-8">
                  State of Practice:
                </td>
                <td className="text-right md:px-8 py-2">
                  {dynamicRequestObject.practiceState}
                </td>
              </tr>
            ) : (
              <tr>
                <td className="text-[#5cbfb1] font-semibold text-left py-2 md:px-8">
                  Type of Practice
                </td>
                <td className="text-right md:px-8 py-2">
                  {formatTextVariables(dynamicRequestObject.practiceType)}
                </td>
              </tr>
            )}

            <tr>
              <td className="text-[#5cbfb1] font-semibold text-left py-2 md:px-8">
                Resident:
              </td>
              <td className="text-right md:px-8 py-2">
                {dynamicRequestObject.resident ? "Yes" : "No"}
              </td>
            </tr>
            <tr>
              <td className="text-[#5cbfb1] font-semibold text-left py-2 md:px-8">
                Year of Practice:
              </td>
              <td className="text-right md:px-8 py-2">
                {dynamicRequestObject.years || "N/A"}
              </td>
            </tr>
            <tr>
              <td className="text-[#5cbfb1] font-semibold text-left py-2 md:px-8">
                Area of Practice:
              </td>
              <td className="text-right md:px-8 py-2">
                {formatTextVariables(dynamicRequestObject.speciality)}
              </td>
            </tr>
            <tr>
              <td className="text-[#5cbfb1] font-semibold text-left py-2 md:px-8">
                Feedback:
              </td>
              <td className="text-right md:px-8 py-2">
                {user.contractReview[0].comments &&
                  user.contractReview[0].comments}
              </td>
            </tr>
            <tr>
              <td className="text-[#5cbfb1] text-left font-semibold md:px-8 py-2">
                File attached:
              </td>
              <td className="flex justify-end items-center md:px-8 py-2">
                <div className="ml-2">
                  <button
                    className="text-white font-semibold bg-[#5cbfb1] px-4 py-2"
                    onClick={() => handleDownload(dynamicRequestObject.file)}
                  >
                    DOWNLOAD
                  </button>
                </div>
              </td>
            </tr>
            {dynamicRequestObject.reviewed && (
              <tr>
                <td className="text-[#5cbfb1] text-left font-semibold md:px-8 py-2">
                  Review Submitted:
                </td>
                <td className="flex justify-end items-center md:px-8 py-2">
                  <div className="ml-2">
                    <button
                      className="text-white font-semibold bg-[#5cbfb1] px-4 py-2"
                      onClick={() =>
                        handleDownload(dynamicRequestObject.reviewLink)
                      }
                    >
                      DOWNLOAD
                    </button>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className=" my-10 font-semibold text-center space-x-5">
          <label className="inline-block px-8 py-2 border-2 border-primary mx-auto cursor-pointer">
            {selectedFile ? (
              <span>{selectedFile.name}</span>
            ) : (
              <span className="font-bold">Upload Feedback</span>
            )}
            <input type="file" className="hidden" onChange={handleFileInput} />
          </label>
          <button
            disabled={selectedFile == null}
            onClick={handleUpload}
            className={`${
              selectedFile == null ? "cursor-not-allowed" : "cursor-pointer"
            } px-8 py-2 border-2 border-primary mx-auto hover:bg-primary hover:text-white transition 300ms`}
          >
            {reqPending ? <span className="loader"></span> : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
}
