import React, { useEffect, useRef } from "react";

export default function ViewportVideoPlayer({
  source,
  type,
  chapterIndex,
  playerRefCallback,
  ...props
}) {
  const playerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.play();
          } else {
            entry.target.pause();
          }
        });
      },
      {
        threshold: 0.5, // Play the video when it's 50% visible
      }
    );

    if (playerRef.current) {
      observer.observe(playerRef.current);
    }

    return () => {
      if (playerRef.current) {
        observer.unobserve(playerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (typeof playerRefCallback === "function") {
      playerRefCallback(playerRef.current);
    }
  }, [playerRefCallback]);

  return (
    <>
      <video ref={playerRef} src={source} {...props} controls />
    </>
  );
}
