import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { loadStripe } from "@stripe/stripe-js";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { addDoc, collection } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AreaOfPractice from "../../../data/practiceAreaList.json";
import { db, storage } from "../../../firebaseConfig";
import fetchUserDataById from "../../../helpers/fetchUserDataById";

const stripePromise = loadStripe("pk_test_KXyLlETj6u3558kLT3LjtC88");

export default function Contract() {
  const [reqPending, setReqPending] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate("/login");
      } else {
        autofillFormData(user);
      }
    });

    // Clean up the event listener when the component unmounts
    return () => unsubscribe();
  }, []);

  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    speciality: "",
    practiceType: "",
    file: "",
    comments: "",
    privacy: false,
  });

  const autofillFormData = async (user) => {
    const fetchedUserData = await fetchUserDataById(user.uid);
    setFormState({
      ...formState,
      firstName: fetchedUserData.firstName,
      lastName: fetchedUserData.lastName,
      email: fetchedUserData.email,
      speciality: fetchedUserData.speciality,
      resident: fetchedUserData.resident,
      years: fetchedUserData.years,
    });
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value =
      target.type === "checkbox"
        ? target.checked
        : target.type === "radio"
        ? target.value === "true"
        : target.value;

    const name = target.name;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileInput = (event) => {
    const file = event.target.files[0];

    // Check if the file is a PDF
    if (file && file.type === "application/pdf") {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
      alert("Please select a PDF file.");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!selectedFile) {
      return alert("Please upload the offer letter as a PDF file.");
    }
    if (formState.phone.length < 10) {
      return alert("Please enter a valid phone number.");
    }
    setReqPending(true);
    const auth = getAuth();
    const timeStamp = Date.now();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const offerLetterRef = ref(
          storage,
          user.uid + "/contractReview/" + timeStamp + ".pdf"
        );
        try {
          await addDoc(collection(db, "users", user.uid, "contractReview"), {
            ...formState,
            timeStamp: timeStamp,
            file: `${user.uid}/contractReview/${timeStamp}.pdf`,
          });
          uploadBytes(offerLetterRef, selectedFile)
            .then(async (snapshot) => {
              alert("Review request submitted successfully!");
              setReqPending(false);
              navigate("/profile/contractReviewList");
            })
            .catch((error) => {
              alert("Error: " + error.message);
              setReqPending(false);
            });
        } catch (error) {
          alert("Error: " + error.message);
          setReqPending(false);
        }
      } else {
        navigate("/login");
      }
    });
  };

  return (
    <>
      <section name="header" className="pt-32">
        <img
          src="logo.png"
          alt="truemed worth logo"
          className="mx-auto md:px-0 px-4"
        />
        <h1 className="text-2xl mt-5">
          Better <span className="text-white">Contract</span>, Brighter{" "}
          <span className="text-white">Future</span>
        </h1>
      </section>
      <section className="consultation form pb-10 md:px-10 px-4">
        <form
          action=""
          className="mt-14 pt-10 bg-white xl:w-1/3 lg:w-1/2 px-10 mx-auto rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)]"
          onSubmit={handleSubmit}
        >
          <h2 className="text-3xl font-bold text-primary">Contract Review</h2>

          <div className="text-left py-10 space-y-4">
            <div>
              <label htmlFor="fName" className="font-bold block">
                First Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                value={formState.firstName}
                onChange={handleInputChange}
                required
                className="bg-gray-200 rounded p-2 w-full"
              />
            </div>
            <div>
              <label htmlFor="lName" className="font-bold block">
                Last Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                value={formState.lastName}
                onChange={handleInputChange}
                required
                className="bg-gray-200 rounded p-2 w-full"
              />
            </div>
            <div>
              <label htmlFor="email" className="font-bold block">
                Email <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={formState.email}
                onChange={handleInputChange}
                required
                className="bg-gray-200 rounded p-2 w-full"
              />
            </div>
            <div>
              <label htmlFor="phone" className="font-bold block">
                Phone Number <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="phone"
                id="phone"
                value={formState.phone}
                onChange={handleInputChange}
                required
                maxLength="10"
                pattern="\d*"
                className="bg-gray-200 rounded p-2 w-full"
              />
            </div>
            <div>
              <label htmlFor="speciality" className="font-bold block">
                Area of Practice <span className="text-red-500">*</span>
              </label>
              <div className="rounded border border-1 border-gray-200">
                <select
                  name="speciality"
                  id="speciality"
                  value={formState.speciality}
                  onChange={handleInputChange}
                  required
                  className="w-full p-2 rounded border-r-8 border-r-transparent"
                >
                  <option value="" selected disabled hidden>
                    Select speciality
                  </option>
                  {AreaOfPractice.map((option) => (
                    <option
                      value={option.replace(/ /g, "_").toLowerCase()}
                      key={option}
                    >
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="type" className="font-bold block">
                Type of Practice <span className="text-red-500">*</span>
              </label>
              <div className="rounded border border-1 border-gray-200">
                <select
                  name="practiceType"
                  id="practiceType"
                  value={formState.practiceType}
                  onChange={handleInputChange}
                  required
                  className="w-full p-2 rounded border-r-8 border-r-transparent"
                >
                  <option value="" selected disabled hidden>
                    Select type of practice
                  </option>
                  <option value="academic">Academic</option>
                  <option value="private_practice">Private Practice</option>
                  <option value="hospital_based">Hospital Based</option>
                  <option value="government">Government</option>
                </select>
              </div>
            </div>

            <div>
              <label className="font-bold block">
                Upload Contract <span className="text-red-500">*</span>
              </label>
              <label className="relative w-full flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg tracking-wide border border-blue cursor-pointer hover:bg-blue text-black font-bold">
                <FontAwesomeIcon
                  icon={faCloudArrowUp}
                  className="text-4xl text-primary my-10"
                />
                {selectedFile ? (
                  <span>{selectedFile.name}</span>
                ) : (
                  <span>
                    <span className="text-primary font-bold">
                      Click to upload
                    </span>{" "}
                    Contract (PDF)
                  </span>
                )}

                <input
                  type="file"
                  className="hidden"
                  onChange={handleFileInput}
                  required
                />
              </label>
            </div>
            <div>
              <label htmlFor="comments" className="font-bold block">
                Comments
              </label>
              <textarea
                name="comments"
                id="comments"
                value={formState.comments}
                onChange={handleInputChange}
                className="resize-none bg-gray-200 w-full p-2 rounded"
                rows={5}
              ></textarea>
            </div>
            <div>
              <input
                type="checkbox"
                name="privacy"
                id="privacy"
                value={formState.privacy}
                onChange={handleInputChange}
                required
                className="mr-2"
              />
              <label htmlFor="privacy" className="font-semibold">
                By submitting this request, I agree to TrueMedWorth’s Terms of
                Use and have read and understand the Privacy Policy.
              </label>
            </div>
            <button
              type="submit"
              className="w-full bg-primary text-white hover:bg-white hover:text-primary hover:border-primary hover:cursor-pointer transition 300ms border-2 border-transparent rounded py-2"
              onChange={handleInputChange}
            >
              {reqPending ? <span className="loader"></span> : "Submit"}
            </button>
          </div>
        </form>
      </section>
    </>
  );
}
