import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import fetchUserDataById from "../../../helpers/fetchUserDataById";
import Compensation from "./Compensation";
import Contract from "./Contract";
import Settings from "./Settings";
import UserSettings from "./UserSettings";

const Dashboard = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        const staffData = await fetchUserDataById(user.uid);
        setIsAdmin(staffData.role === "admin");
        if (!staffData.approved) navigate("/profile/edit");
      } else {
        navigate("/login");
      }
    });

    // Clean up the event listener when the component unmounts
    return () => unsubscribe();
  }, [auth, navigate]);

  const NoTabPaths = ["/dashboard/signup"];

  if (user) {
    return (
      <div className="bg-white pt-40 px-[10%]">
        {NoTabPaths.includes(location.pathname) ? null : (
          <div className=" text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
            <h1 className="text-3xl text-black font-semibold">Dashboard</h1>
            <ul className="flex flex-wrap -mb-px">
              <li className="mr-2">
                <Link
                  to="/dashboard/compensation"
                  className={`${
                    location.pathname === "/dashboard/compensation"
                      ? "text-primary rounded-t-lg active dark:text-primary"
                      : "rounded-t-lg hover:text-gray-600  dark:hover:text-gray-300"
                  } inline-block p-4 transition 300ms`}
                >
                  Compensation Consultation
                </Link>
              </li>
              <li className="mr-2">
                <Link
                  to="/dashboard/contract"
                  className={`${
                    location.pathname === "/dashboard/contract"
                      ? "text-primary rounded-t-lg active dark:text-primary"
                      : "rounded-t-lg hover:text-gray-600  dark:hover:text-gray-300"
                  } inline-block p-4 transition 300ms`}
                  aria-current="page"
                >
                  Contract Review
                </Link>
              </li>
              {isAdmin && (
                <li className="mr-2">
                  <Link
                    to="/dashboard/settings"
                    className={`${
                      location.pathname === "/dashboard/settings"
                        ? "text-primary border-b-2 border-primary rounded-t-lg active dark:text-primary dark:border-primary"
                        : "border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                    } inline-block p-4 transition 300ms`}
                    aria-current="page"
                  >
                    Settings
                  </Link>
                </li>
              )}
              {isAdmin && (
                <li className="mr-2">
                  <Link
                    to="/dashboard/userSettings"
                    className={`${
                      location.pathname === "/dashboard/userSettings"
                        ? "text-primary border-b-2 border-primary rounded-t-lg active dark:text-primary dark:border-primary"
                        : "border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                    } inline-block p-4 transition 300ms`}
                    aria-current="page"
                  >
                    User Settings
                  </Link>
                </li>
              )}
            </ul>
          </div>
        )}

        <Outlet />
      </div>
    );
  } else {
    return null;
  }
};

export { Compensation, Contract, Dashboard, Settings, UserSettings };
