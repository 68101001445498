import { collection, doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../../firebaseConfig";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import fetchUserDataById from "../../../helpers/fetchUserDataById";
import {
  formatTextVariables,
  formatTimestamp,
} from "../../../helpers/formatters";
import { deleteDoc } from "firebase/firestore";
import { Oval } from "react-loader-spinner";
// import { deleteUser as deleteAuthUser } from "firebase/auth";

export default function UserSettings() {
  const [loading, setloading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [reqPending, setReqPending] = useState(false);
  const navigate = useNavigate();

  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        navigate("/login");
      } else {
        const userData = await fetchUserDataById(user.uid);
        if (!userData.approved || userData.role !== "admin") {
          navigate("/");
        }
      }
    });

    // Clean up the event listener when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = listenToUserData();
    return () => {
      unsubscribe();
    };
  }, []);

  const listenToUserData = () => {
    setloading(true);
    const collectionRef = collection(db, "users");
    const unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => {
        const docData = doc.data();
        return {
          ...docData,
          id: doc.id,
        };
      });
      setUserData(data);
      console.log(userData);
    });
    setloading(false);
    return unsubscribe;
  };

  const handleDeleteUser = async (userId) => {
    const confirmation = window.confirm(
      "Are you sure you want to delete this user?"
    );

    if (confirmation) {
      try {
        // Delete the user document from Firestore
        const userDocRef = doc(db, "users", userId);
        await deleteDoc(userDocRef);
        console.log("User and associated data deleted successfully!");
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
  };

  return (
    <div>
      {loading ? (
        <div className="flex items-center justify-center">
          <Oval
            height={80}
            width={80}
            color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <table className="table-auto w-full text-left whitespace-no-wrap">
          <thead>
            <tr>
              <th className="px-4 py-5 title-font tracking-wider font-medium text-gray-900 text-sm bg-primary rounded-tl rounded-bl">
                UserName
              </th>
              <th className="px-4 py-5 title-font tracking-wider font-medium text-gray-900 text-sm bg-primary">
                E-mail
              </th>
              <th className="px-4 py-5 title-font tracking-wider font-medium text-gray-900 text-sm bg-primary">
                Resident/Fellow
              </th>
              <th className="px-4 py-5 title-font tracking-wider font-medium text-gray-900 text-sm bg-primary">
                State of Practice
              </th>
              <th className="px-4 py-5 title-font tracking-wider font-medium text-gray-900 text-sm bg-primary">
                Years of Practice
              </th>
              <th className="px-4 py-5 title-font tracking-wider font-medium text-gray-900 text-sm bg-primary">
                Speciality
              </th>
              <th className="px-4 py-5 title-font tracking-wider font-medium text-gray-900 text-sm bg-primary">
                Date of Joining
              </th>
              <th className="px-4 py-5 title-font tracking-wider font-medium text-gray-900 text-sm bg-primary">
                Chapters Explored
              </th>
              <th className="px-4 py-5 title-font tracking-wider font-medium text-gray-900 text-sm bg-primary">
                Actions
              </th>
              <th className="px-4 py-5 title-font tracking-wider font-medium text-gray-900 text-sm bg-primary"></th>
            </tr>
          </thead>
          <tbody>
            {userData
              .sort((a, b) => b.joiningDate - a.joiningDate)
              .map((user, index) => (
                <tr key={index}>
                  <td className="px-4 py-6">
                    {user.firstName + " " + user.lastName}
                  </td>
                  <td className="px-4 py-6">{user.email}</td>
                  <td className="px-4 py-6">{user.resident ? "Yes" : "No"}</td>
                  <td className="px-4 py-6">{user.practiceState}</td>
                  <td className="px-4 py-6">{user.years}</td>
                  <td className="px-4 py-6">
                    {user.speciality && formatTextVariables(user.speciality)}
                  </td>
                  <td className="px-4 py-6">
                    {user.joiningDate && formatTimestamp(user.joiningDate)}
                  </td>
                  <td className="px-4 py-6">
                    {user.chaptersDone ? user.chaptersDone.length : "0"}
                  </td>
                  <td>
                    <button
                      className="rounded-lg text-primary border-2 border-primary p-2 w-full hover:text-white hover:bg-primary transition 300ms"
                      onClick={() => handleDeleteUser(user.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
