import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function AudioPlayer({ chapterIndex, src, closeModal }) {
  return (
    <div className="z-20 h-screen w-screen bg-black bg-opacity-50 fixed flex items-center justify-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
      <div className="bg-[#161515] rounded-lg p-4 relative">
        <audio controls autoPlay>
          <source src={src} type="audio/mp3" />
        </audio>
        <h2 className="text-white">
          {chapterIndex
            ? `TrueMedWorth Negotiation 101 - Chapter ${chapterIndex}`
            : ""}
        </h2>
        <button
          onClick={closeModal}
          className="text-white absolute right-2 top-2"
        >
          <FontAwesomeIcon icon={faCircleXmark} />
        </button>
      </div>
    </div>
  );
}

export default AudioPlayer;
