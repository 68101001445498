import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const auth = getAuth();
  const navigate = useNavigate();

  const triggerResetEmail = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      alert("A password reset link has been sent to your email!");
      navigate("/login");
    } catch (err) {
      alert("Couldn't reset your password:" + err.message);
    }
  };

  return (
    <>
      <section
        name="password-form"
        className="md:mt-0 mt-20 pt-32 h-screen flex items-center justify-center"
      >
        <div className="lg:w-1/3 md:w-1/2 md:px-0 px-4">
          <div className=" bg-white px-10 pt-10 mx-auto rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
            <p className="text-xl">
              Reset your password <br /> for TrueMedWorth
            </p>
            <form
              onSubmit={triggerResetEmail}
              className="py-10 w-full text-left space-y-5"
            >
              <div>
                <label htmlFor="email" className="font-bold block">
                  E-mail <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="bg-gray-200 rounded p-2 w-full"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <input
                type="submit"
                value="Reset Password"
                className="w-full bg-primary text-white hover:bg-white hover:text-primary hover:border-primary hover:cursor-pointer transition 300ms border-2 border-transparent rounded py-2"
              />
            </form>
          </div>
          <p className="mt-14 mb-10 pb-20 font-semibold text-center space-x-2">
            <Link to="/login">
              <span className="text-blue-500 underline">Login</span>
            </Link>
            <span>or</span>

            <Link to="/signup">
              <span className="text-blue-500 underline">Register</span>
            </Link>
          </p>
        </div>
        <div className="py-10"></div>
      </section>
    </>
  );
}
